.cardCollection-container {
	z-index: 3;
	/* overflow: auto; */
	background-color: rgba(0,0,0,0.0);
	width: 33.2vw;
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* Title */
.cardCollection-container .title1 {
	font-size: 15px;
	font-weight: 800;
	letter-spacing: 3.5px;
	margin: 45px 0 50px 0;
	background-color: rgba(0,0,0,0.0)
}

.cardCollection-container p {background-color: rgba(0,0,0,0.0)}



/* ___________ DESKTOP ___________ */

/* Card */
.Card {
	display: grid;
	padding: 40px 0;
	position: relative;
	background-color: rgba(0, 0, 0, 0);
	-webkit-transition: all 4s;
	transition: all .4s;
}

/* Background */
.Card .background {
	border-radius: 15px;
	background-color: white;
	grid-row: 1/6;
	grid-column: 1/2;
	box-shadow: 0 20px 60px rgba(0,0,0,.04)
}

/* Item Image */
.Card .image-container {
	grid-row: 1/2;
	grid-column: 1/2;
	margin: 23px 23px 23px 23px;
	background-color: rgba(0, 0, 0, 0);	
	box-shadow: 0 20px 50px 0 rgba(0,0,0,.07);
}

.Card .image-container img {
	/* object-fit: contain; */
	border-radius: 12px;
}



/* Info Text */
.Card .info-container {
	grid-column: 1/2;
	grid-row: 2/3;
	display: flex;
	flex-direction: column;
	margin: 0px 23px;
	text-transform: uppercase;
	background-color: rgba(0,0,0,0.0);
}

.Card .info-container p {
	color: #282828;
	width: 100%;
	text-align: left;
	z-index: 1;
}

.Card .info-container .brand {
	font-size: 16px;
	font-weight: 800;
	letter-spacing: 0px;
}

.Card .info-container .title {
	font-weight: 600;
	letter-spacing: 0px;
	margin: 5px 0 0 0;
	font-size: 10px;
}

/* Price */
.Card .info-container .price {
	font-weight: 700;
	font-size: 11px;
	letter-spacing: 1px;
	background-color: rgba(0,0,0,0.0);
}


/* MORE INFOS */
.more-infos-container {
	margin: 23px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.more-info {
	width: calc(100% / 2 - 15px);
	max-width: calc(100% / 2 - 15px);
	display: flex;
	flex-direction: column;
}

.more-info .title {
	font-size: 10px;
	font-weight: 600;
}

.more-info .text {
	font-size: 13px;
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: inherit;
}


/* ALTERNATIV & BUY */
.Card .button-container {
	grid-column: 1/2;
	grid-row: 3/4;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: rgba(0, 0, 0, 0);
}

.Card .alternativ-link {
	width: calc(100% - 46px);
}

.Card .alternativ-button {
	height: 36px;
	width: 100%;
	background-color: #ECECEC;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 11px;
	font-weight: 700;
}

/* Buy */
.Card .buyBut {
	margin-top: 14px;
	border-radius: 8px;
	width: calc(100% - 46px);
	height: 38px;
	background-color: #363636;
	-webkit-tap-highlight-color: #4e4e4e;
	display: flex;
	justify-content: center;
	align-items: center;
	-moz-transition: background-color 0.2s cubic-bezier(0.16, 0.08, 0.355, 1);
	-webkit-transition: background-color 0.2s cubic-bezier(0.16, 0.08, 0.355, 1);
	transition: background-color 0.2s cubic-bezier(0.16, 0.08, 0.355, 1);
	font-size: 13px;
	font-weight: 700;
	color: #FFFFFF;
	letter-spacing: 0.3px;
}

.Card .buyBut:hover {background-color: #1d1d1d;}
.Card .buyBut:active {background-color: #1d1d1d;}







/* ___________ TABLET ___________ */
@media only screen and (max-width: 1290px) {
	.cardCollection-container {
		width: 44.55vw;
		padding-top: 72px;
	}

	.Card .buyBut:hover {background-color: #363636;}
	.Card .buyBut:active {background-color: #363636;}


}



@media only screen and (min-width: 745px) and (max-width: 934px) {
	.cardCollection-container {
		width: 100vw;
		z-index: 9;
		background-color: #F7F7F7;
		padding: 0px 73px; 
		display: flex;
		flex-direction: column;
	}

	.Card {
		width: 100%
	}


	.Card .background {
		margin-top: 50px;
		grid-column: 1/3;
		grid-row: 1/3;
	}
	
	
	/* Item Image */
	.Card .image-container {
		grid-row: 1/2;
		grid-column: 1/2;
		margin: 0 60px 39px 60px;
	}
	
	
	
	/* Info Text */
	.Card .info-container {
		grid-column: 2/3;
		grid-row: 1/2;
		margin: 83px 60px 25px 0;
	}
	
	.Card .info-container p {
		color: #282828;
		width: 100%;
		text-align: left;
	}
	
	.Card .info-container .brand {
		font-size: 20px;
		font-weight: 700;
		letter-spacing: 6px;
	}
	
	.Card .info-container .title {
		font-weight: 500;
		letter-spacing: 5px;
		margin: 11px 0 0 0;
		font-size: 10px;
	}
	
	/* Price */
	.Card .info-container .priceP {margin-top: 20px;}
	
	.Card .info-container .price {
		font-size: 13px;
		letter-spacing: 2px;
	}
	
	.Card .info-container .priceInSale {
		margin-top: 22px;
	}
	
	.Card .info-container .salePrice {
		font-size: 13px;
		letter-spacing: 2px;
	}
	

	/* Buy & Alternativ*/
	.Card .button-container {
		grid-column: 1/3;
		grid-row: 2/3;
		align-items: baseline;
		flex-direction: row;
		padding: 0 60px;
	}

	.alternativ-link {
		order: 2;
	}
	.alternativ-button {
		/* width: calc(30% - 50px); */
		/* margin-left: 50px; */
	}
	.Card .buyBut {
		order: 1;
		margin: 0;
	}

}









@media only screen and (max-width: 744px) {
	.cardCollection-container {
		width: 100vw;
		z-index: 9;
		background-color: #F7F7F7;
		padding: 0px 73px; 
		display: flex;
		flex-direction: column;
	}


	.Card .background {
		margin-top: 45px;
	}

	/* Image */
	.Card .image-container {
		margin: 0 36px 36px 36px;
	}


	/* Item Infos */
	.Card .info-container {
		margin: 0px;
		display: flex;
		flex-direction: column;
		padding: 0 36px;
	}

	.Card .info-container p {
		text-align: center;
	}

	.Card .info-container .brand {
		font-weight: 800;
		font-size: 16px;
		color: #282828;
		letter-spacing: 4px;
	}

	.Card .info-container .title {
		width: auto;
		margin-top: 6px;
		font-size: 9px;
		color: #282828;
		letter-spacing: 3px;
		padding: 0;
	}


	/* Buy & Stocklist Button Infos */
	.Card .buyBut {
		height: 40px;
		background-color: #363636;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.Card .more-info .title {
		text-align: left;
	}

}




@media only screen and (min-height: 1200px) and (max-width: 1290px) {
	.cardCollection-container {
		width: 100%;
		z-index: 9;
		/* background-color: rgba(230, 230, 230, 0.96); */
		background-color: #F7F7F7;
		/* backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px); */
		padding-bottom: 50px; 
	}
}
/* 
@media only screen and (max-width: 1200px) {
	.cardCollection-container {
		padding-bottom: 50px;
	}
}

@media only screen and (max-width: 750px) {
	.cardCollection-container {
		padding-bottom: 50px;
	}
} */