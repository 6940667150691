.Filter{
	z-index: 9;
	top: 8.4375rem; left: 0;
	position: absolute;
	display: flex;
	align-items: center;
}

.Filter .shortBy {
	z-index: 11;
	padding-left: 07.083333vw;
	letter-spacing: 4px;
}

.Filter .firstPart {
	font-weight: 600;
	background-color: rgba(0, 0, 0, 0);
}

.Filter .lastPart {
	letter-spacing: 3px;
	margin-left: 10px;
	font-weight: 400;
	background-color: rgba(0, 0, 0, 0);
}

.Filter .selection {
	z-index: 10;
	display: flex;
	-webkit-transition: 0.6s;
	transition: 0.6s;
	-webkit-transform: translateX(-4000px);
	transform: translateX(-4000px);
}

.Filter .selection .filterName {
	color: #919191;
	margin-left: 30px;
	letter-spacing: 2px;
	transition: 0.3s;
	-webkit-transition: 0.3s;
}

.Filter .selection .filterName:hover {
	color: #282828;
}







/* Phone Sort overlay */
.overlay-container div {background-color: white;}
.overlay-container {
	z-index: 99;
	position: fixed;
	width: 100vw;
	height: 100vh;
	bottom: 0;
	/* overflow: hidden; */
	-webkit-animation-duration: .4s;
	animation-duration: .4s;
}

.overlay-container .menu-container {
	position: absolute;
	width: 100vw;
	bottom: 0;
	-webkit-transition: max-height 0.4s;
	transition: max-height 0.4s;
	-webkit-transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
}

.overlay-container .closeButton {
	background-color: white;
	position: absolute;
	right: 0; top: 0;
	padding: 15px;
}

.overlay-container .closeButton .icon {
	background-color: white;
	width: 15px;
	height: 15px;
}


.overlay-container .sortChoice {
	background-color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	margin: 50px 0;
	height: 114px;
}

.overlay-container .sortChoice .sortName {
	background-color: white;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 2px;
	color: #919191;
}


 @-webkit-keyframes openMenu {
	0% {
		background-color: rgba(0, 0, 0, 0);
		backdrop-filter: blur(0);
		-webkit-backdrop-filter: blur(0);
	}
	100% {
		background-color: rgba(0, 0, 0, 0.47);
		backdrop-filter: blur(16px);
		-webkit-backdrop-filter: blur(16px);
	}
  }
  
  @keyframes openMenu {
	0% {
		background-color: rgba(0, 0, 0, 0);
		backdrop-filter: blur(0);
		-webkit-backdrop-filter: blur(0);
	}
	100% {
		background-color: rgba(0, 0, 0, 0.47);
		backdrop-filter: blur(16px);
		-webkit-backdrop-filter: blur(16px);
	}
  }


  @-webkit-keyframes closeMenu {
	0% {
		background-color: rgba(0, 0, 0, 0.47);
		backdrop-filter: blur(16px);
		-webkit-backdrop-filter: blur(16px);
	}
	100% {
		background-color: rgba(0, 0, 0, 0);
		backdrop-filter: blur(0);
		-webkit-backdrop-filter: blur(0);
	}
  }

  @keyframes closeMenu {
	0% {
		background-color: rgba(0, 0, 0, 0.47);
		backdrop-filter: blur(16px);
		-webkit-backdrop-filter: blur(16px);
	}
	100% {
		background-color: rgba(0, 0, 0, 0);
		backdrop-filter: blur(0);
		-webkit-backdrop-filter: blur(0);
	}
  }


  









/* Tablet */
@media only screen and (max-width: 1200px) {
	.Filter {
		top: 99px; left: 0;
	}

	.Filter .shortBy {
		padding-left: 40px;
	}
}

/* Phone */
@media only screen and (max-width: 750px) {
	.Filter { 	
		top: 48px; left: 0;
		background-color: rgba(0, 0, 0, 0);
	}

	.Filter .shortBy {
		padding: 0 10px 0 18px;
		background-color: rgba(0, 0, 0, 0);
	}

	.Filter .shortBy .firstPart {
		background-color: rgba(0, 0, 0, 0);
		color: #585858;
	}
}

