.preview {
	position: relative;
}

.preview-container {
	background-color: #F7F7F7;
	display: flex;
	width: 100%;
	/* height: 100vh;    other scroll!!    */
	/* justify-content: stretch; */
	height: auto;
}

button {
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
	font-family: 'Montserrat', sans-serif;
	-webkit-tap-highlight-color: transparent;
}


/* .footer {
	width: 100vw;
	height: 10px;
	background-color: red;
	margin-top: 48px;
} */


@media only screen and (max-width: 1290px) {
	/* .preview-container {
		flex-direction: column;
		height: auto;
	} */
}

/* phone */
@media only screen and (max-width: 934px) {
	.preview-container {
		flex-direction: column;
		height: auto;
	}
}

@media only screen and (min-height: 1200px) and (max-width: 1290px) {
	.preview-container {
		flex-direction: column;
		height: auto;
	}
}