/* Older browsers (do now support CSS Environment variables). */
.Home {
	height: 100%;
  }
  
  /* Browsers which partially support CSS Environment variables (iOS 11.0-11.2). */
  @supports (padding-bottom: constant(safe-area-inset-bottom)) {
	.Home {
	  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
	  height: calc(100vh + var(--safe-area-inset-bottom));
	}
  }
  
  /* Browsers which fully support CSS Environment variables (iOS 11.2+). */
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
	.Home {
	  --safe-area-inset-bottom: env(safe-area-inset-bottom);
	  height: calc(100vh + var(--safe-area-inset-bottom));
	}
  }


/* CUSTOM MOBILE NAV */
.MobileHomeNav {
	width: 100vw;
	position: absolute;
	top: 0;
	margin-top: 31px;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0);
}

.MobileHomeNav .brand {
	color: black;
	font-weight: 800;
	font-size: 25px;
	letter-spacing: 3.7px;
	text-align: center;
}

.MobileHomeNav h1 {
	font-weight: 700;
	font-size: 11px;
	letter-spacing: 1.7px;
	text-align: center;
	margin-top: 24px;
	background-color: rgba(0, 0, 0, 0);
}

.MobileHomeNav .women {
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 4px;
	text-align: center;
	margin-top: 52px;
}

.MobileHomeNav .men {
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 4px;
	text-align: center;
	margin-top: 11px;
}


/* IMAGES */
/* Desktop */
.Desktop .header-2 {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	margin-top: 73px;
	z-index: 9;
	background-color: rgba(0, 0, 0, 0);
}
.Desktop h2 {
	font-size: 11px;
	letter-spacing: 6px;
	font-weight: 700;
}

.Desktop .line {
	height: 1px;
	width: 71px;
	background-color: #383838;
	margin-top: 22.5px;
}


.Home .Desktop-images {
	position: absolute;
	width: 100vw;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	padding-left: 27.7vw;
	/* padding-right: 13vw; */
	height: 100vh;
	background-color: rgba(0, 0, 0, 0);
	z-index: 0;
}

.Home .image1 {
	object-fit: contain;
	/* width: 31.944444vw; */
	height: 67.317073vh;
	z-index: 2;
	max-height: 73vh;
	background-color: rgba(0, 0, 0, 0);
}

.Home .image2 {
	object-fit: contain;
	/* width: 39.305556vw; */
	margin-left: -132px;
	height: 82.910156vh;
	/* margin-left: -9.166667vw; */
	z-index: 1;
	max-height: 88vh;
}


/* Mobile */
.Home .mainImage {
	height: 100vh;
	object-fit: cover;
	/* padding-bottom: 0px; */
	width: 100vw;
}

.Home .image {
	position: relative;
}



@media only screen and (max-width: 1290px) {
	.Home {
		height: calc(100vh + 97px);
		position: relative;
	}

	@supports (padding-bottom: constant(safe-area-inset-bottom)) {
		.Home {
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		height: calc(100vh + 97px + var(--safe-area-inset-bottom));
		}
	}
	@supports (padding-bottom: env(safe-area-inset-bottom)) {
		.Home {
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		height: calc(100vh + 97px + var(--safe-area-inset-bottom));
		}
	}
}

@media only screen and (max-width: 775px) {
	.Home {
		height: calc(100vh + 147px);
		position: relative;
	}
		
	@supports (padding-bottom: constant(safe-area-inset-bottom)) {
		.Home {
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		height: calc(100vh + 147px + var(--safe-area-inset-bottom));
		}
	}
	@supports (padding-bottom: env(safe-area-inset-bottom)) {
		.Home {
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		height: calc(100vh + 147px + var(--safe-area-inset-bottom));
		}
	}
}

@media only screen and (max-width: 430px) {
	.Home {
		position: relative;
		height: calc(100vh + 174px);
	}
	
	@supports (padding-bottom: constant(safe-area-inset-bottom)) {
		.Home {
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		height: calc(100vh + 174px + var(--safe-area-inset-bottom));
		}
	}
	@supports (padding-bottom: env(safe-area-inset-bottom)) {
		.Home {
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		height: calc(100vh + 174px + var(--safe-area-inset-bottom));
		}
	}
}