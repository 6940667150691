.wrapper {
	position: relative;
}

.CookieAlert {
	width: 255px;
	max-height: auto;
	position: fixed;
	overflow: hidden;
	bottom: 29px; right: 29px;
	padding: 27px 27px 21px 27px;
	background-color: white;
	box-shadow: 0 20px 40px 0 rgba(0,0,0,0.14);
	-webkit-transition: max-height 0.8s ease-in-out;
	-moz-transition: max-height 0.8s ease-in-out;
	transition: max-height 0.8s ease-in-out;
	z-index: 200;
}

.CookieAlert div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.CookieAlert .title {
	width: 100%;
	text-align: left;
	font-weight: 800;
	letter-spacing: 1.2px;
	font-size: 12px;
}

.CookieAlert .contentText {
	width: 100%;
	text-align: left;
	font-weight: 500;
	letter-spacing: 1px;
	font-size: 11px;
	text-transform: none;
	line-height: 16px;
	margin-top: 12px;
}

.CookieAlert .acceptButton {
	width: 100%;
	height: 39px;
	font-weight: 800;
	letter-spacing: 1.2px;
	font-size: 11px;
	color: white;
	background-color: #2F2F2F;
	margin-top: 43px;
	transition: .4s ease-in-out;
}

.CookieAlert .acceptButton:hover {
	color: #282828;
	background-color: white;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.10);
	/* transform: translateY(-6px); */
}

.CookieAlert .individuellButton {
	width: max-content;
	font-weight: 700;
	letter-spacing: 1.2px;
	font-size: 11px;
	margin-top: 21px;
}



/*  */

.CookieAlert .contentInduviduellText {
	width: 100%;
	text-align: left;
	text-transform: none;
	font-weight: 500;
	letter-spacing: 1px;
	font-size: 10px;
	margin-top: 12px;
}

.CookieAlert .saveButton {
	width: max-content;
	font-weight: 800;
	letter-spacing: 2px;
	font-size: 11px;
	margin-top: 35px;
}

.CookieAlert .acceptAllButton {
	width: 100%;
	height: 39px;
	font-weight: 800;
	letter-spacing: 1.2px;
	font-size: 11px;
	color: white;
	background-color: #2F2F2F;
	margin-top: 18px;
	transition: .4s ease-in-out;
}

.CookieAlert .acceptAllButton:hover {
	color: #282828;
	background-color: white;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.10);
}

/* Checkbox */
.CookieAlert .checkbox {
	margin-top: 27px;
	align-self: flex-start;
	display: flex;
	align-items: center;
	justify-content: center;
}

.CookieAlert .checkbox .circle {
	width: 15px;
	min-width: 15px;
	height: 15px;
	border: 2px solid #282828;
	border-radius: 50%;
	transition: .13s ease-in-out;
}

.CookieAlert .checkbox .title {
	margin-left: 12px;
	font-size: 11px;
	font-weight: 700;
}



@media only screen and (max-width: 570px) { 
	.CookieAlert {
		/* position: -webkit-sticky;
		position: sticky;
		width: 100vw;
		bottom: 0; right: 0; */
		bottom: 20px; right: 20px;
	}
}
