.Newsletter {
	z-index: 110;
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	justify-content: center;
	align-items: center;
}

.Newsletter .alert {
	z-index: 110;
	max-width: 41.375rem;
	margin: 0 0.875rem;
	width: 100%;
	height: max-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #EFEFEF;
	transition: 3.1s;
}

.Newsletter .title {
	font-weight: 800;
	font-size: 1.06rem;
	margin-top: 2.3125rem;
	letter-spacing: 0.5px;
}

.Newsletter .text {
	font-weight: 600;
	/* font-size: 0.8125rem; */
	font-size: 11px;
	margin: 2.625rem 1.5625rem 0 1.5625rem;
	letter-spacing: 1px;
	text-align: center;
}

.Newsletter .textField {
	font-weight: 500;
	font-size: 0.8125rem;
	letter-spacing: 1.5px;
	max-width: 26.44rem;
	/* width: 100%; */
	width: calc(100% - 1.5625rem - 1.5625rem);
	margin: 1.875rem 1.5625rem 0 1.5625rem;
	border: none;
	text-align: center;
	padding: 11px;
	font-family: 'Montserrat', sans-serif;
	outline: none;
	text-transform: uppercase;
}

.Newsletter .errMessage {
	font-weight: 700;
	font-size: 0.5625rem;
	margin: 0.25rem 1.5625rem 0 1.5625rem;
	letter-spacing: 3px;
	text-align: center;
	text-transform: uppercase;
	color: #D34B4B;
}

.Newsletter .nextStep {
	font-weight: 800;
	font-size: 0.5rem;
	margin: 0.8125rem 1.5625rem 0 1.5625rem;
	letter-spacing: 1.1px;
	text-align: center;
	text-transform: uppercase;
}

.Newsletter .subBut {
	font-weight: 800;
	font-size: 0.8125rem;
	margin: 3.1875rem 0 2.0625rem;
	background-color: rgba(0, 0, 0, 0);
}




.Newsletter .overlay {
	z-index: 109;
	position: absolute;
	left: 0; right: 0; top: 0; bottom: 0;
	background-color: rgba(0, 0, 0, 0.29);
}



@media only screen and (max-width: 420px) {
	.Newsletter .title {
		margin-top: 1.25rem;
	}
	.Newsletter .text {
		margin-top: 1.5625rem;
	}
	
	.Newsletter .textField {
		margin-top: 1.5625rem;
	}
	
	.Newsletter .errMessage {

	}
	
	.Newsletter .nextStep {

	}
	
	.Newsletter .subBut {
		margin-bottom: 1.25rem;
	}
}