.SimilarItems {
	background-color: #F4F4F4;
	min-height: 100vh;
	position: relative;
}

.SimilarItems-collection {
	background-color: rgba(0, 0, 0, 0);
	margin-left: 217px;
	padding: 135px 90px;
	display: flex;
	flex-direction: column;
}



/* --- ITEM --- */
.SimilarItems-collection .item {
	position: relative;
	margin-bottom: 100px;
	display: grid;
	background-color: rgba(0, 0, 0, 0);
}

.SimilarItems-collection :last-child {
	margin-bottom: 0px;
}


/* Background */
.SimilarItems-collection .background {
	grid-column: 1/3;
	grid-row: 2/4;
	background-color: white;
}


/* Images */
.SimilarItems-collection .images {
	grid-column: 1/2;
	grid-row: 1/4;
	padding: 0 60px 60px 60px;
	background-color: rgba(0, 0, 0, 0);
	display: flex; 
	justify-content: space-between;
}

.SimilarItems-collection .images img {
	object-fit: contain;
	box-shadow: 0 20px 50px 0 rgba(0,0,0,0.10);
	background-color: rgba(0, 0, 0, 0);
}


/* Item Infos */
.SimilarItems-collection .itemInfos {
	grid-column: 2/3;
	grid-row: 2/3;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	flex-direction: column;
	margin: 38px 0 54px;
}

.SimilarItems-collection .brand {
	font-weight: 700;
	font-size: 20px;
	color: #282828;
	letter-spacing: 6.5px;
}

.SimilarItems-collection .title {
	margin-top: 11px;
	font-size: 10px;
	color: #282828;
	letter-spacing: 6px;
	padding-right: 45px;
}

.SimilarItems-collection .priceP {margin-top: 20px;}

.SimilarItems-collection .price {
	font-weight: 700;
	font-size: 13px;
	color: #282828;
	letter-spacing: 2px;
}

.SimilarItems-collection .priceInSale {
	font-weight: 600;
	font-size: 10px;
	letter-spacing: 2px;
	text-decoration: line-through;
}

.SimilarItems-collection .salePrice {
	font-weight: 700;
	font-size: 13px;
	color: #282828;
	letter-spacing: 2px;
	text-decoration: none;
	margin-left: 15px;
}


/* Buy & Stocklist Button Infos */
.SimilarItems-collection .action-container {
	grid-column: 2/3;
	grid-row: 3/4;
	padding: 0 73px 60px 0;
	display: flex;
	justify-content: space-between;
}

.SimilarItems-collection .buyBut {
	margin: 0 41px 0 0;
	width: 100%;
	background-color: #363636;
	-webkit-tap-highlight-color: #4e4e4e;
	display: flex;
	align-items: center;
	-moz-transition: background-color 0.2s cubic-bezier(0.16, 0.08, 0.355, 1);
	-webkit-transition: background-color 0.2s cubic-bezier(0.16, 0.08, 0.355, 1);
	transition: background-color 0.2s cubic-bezier(0.16, 0.08, 0.355, 1);
}

.SimilarItems-collection .buyBut:hover {
	background-color: #1d1d1d;
	/* background-color: #4A90E2; */
}

.SimilarItems-collection .buyBut:hover p {
	margin-left: 27px;
}

.SimilarItems-collection .buyBut p {
	font-size: 17px;
	font-weight: 700;
	color: #FFFFFF;
	letter-spacing: 3px;
	text-transform: uppercase;
	margin-left: 20px;
	-moz-transition: 0.3s;
	-webkit-transition: 0.3;
	transition: 0.3s;
}

.SimilarItems-collection .buyBut .soldOut {
	font-size: 8px;
	letter-spacing: 1px;
	background-color: rgba(0, 0, 0, 0);
}

.SimilarItems-collection .stocklist {
	font-weight: 600;
	font-size: 10px;
	color: #919191;
	letter-spacing: 3.5px;
	text-transform: uppercase;
}


/* STOCKLIST OVERLAY */
.SimilarItems-stocklist-container {
	grid-column: 2/3;
	grid-row: 1/4;
	position: absolute;
	bottom: 0; right: 0;
	width: 176px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	background-color: #282828;
	overflow-y: hidden;
	-webkit-transition-property: all;
	transition-property: all;
	-webkit-transition: .6s;
	transition: .6s;
	-webkit-transition-timing-function: cubic-bezier(.2,.64,.39,.99);
	transition-timing-function: cubic-bezier(.2,.64,.39,.99);
	z-index: 11;
}

.SimilarItems-stocklist-container .listContainer { background-color: rgba(0, 0, 0, 0)}

.SimilarItems-stocklist-container .closeButton {
	background-color: rgba(0, 0, 0, 0);
	height: 23px;
	width: 100%;
	padding: 13px 0 7px 17px;
	text-align: center;
}

.SimilarItems-stocklist-container .closeButton div {
	background: #919191;
	width: 20px;
	height: 3px;	
	
	transition: background-color .3s;
	-webkit-transition: background-color .3s;
	-webkit-tap-highlight-color: #FFFFFF;
}

.SimilarItems-stocklist-container .closeButton:hover div {
	background-color: #FFFFFF;
}

.SimilarItems-stocklist-container .listButton {
	background-color: rgba(0, 0, 0, 0);
	width: 100%;
	height: 42px;
	padding: 7px 17px;
	margin-top: 6px;
}

.SimilarItems-stocklist-container .listContainer:last-child {
	margin-bottom: 13px;
}


.SimilarItems-stocklist-container .brand {
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 2px;
	text-align: left;
}

.SimilarItems-stocklist-container .stock {
	font-weight: 700;
	font-size: 8px;
	letter-spacing: 1px;
	text-align: left;
}

.SimilarItems-stocklist-container .listButton:hover .brand {
	color: white;
}



/* new Nav */
@media only screen and (max-width: 1290px) {
	.SimilarItems-collection {
		margin-left: 0px;
		padding: 135px 90px calc(135px + 97px) 90px;
	}

	.SimilarItems-collection .buyBut:hover {background-color: #363636;}
	.SimilarItems-collection .buyBut:active {background-color: #363636;}
	.SimilarItems-collection .buyBut:hover p {margin-left: 20px;}
}

@media only screen and (max-width: 950px) {
	.SimilarItems-collection {
		padding: 135px 65px calc(135px + 55px) 65px;
	}
}



/* phone */
@media only screen and (max-width: 870px) {

	.SimilarItems-collection {
		align-items: center;
		padding-bottom: 230px;
	}

	/* Background */
	.SimilarItems-collection .background {
		grid-column: 1/2;
		grid-row: 1/5;
		margin-top: 45px;
	}


	/* Images */
	.SimilarItems-collection .images {
		grid-column: 1/2;
		grid-row: 1/2;
		padding: 0 36px 36px 36px;
		background-color: rgba(0, 0, 0, 0);
		display: block; 
	}




	/* Item Infos */
	.SimilarItems-collection .itemInfos {
		grid-column: 1/2;
		grid-row: 2/3;
		margin: 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 36px;
	}

	.SimilarItems-collection .brand {
		font-weight: 800;
		font-size: 16px;
		color: #282828;
		letter-spacing: 4px;
	}

	.SimilarItems-collection .title {
		width: auto;
		margin-top: 6px;
		font-size: 9px;
		color: #282828;
		letter-spacing: 3px;
		padding: 0;
		text-align: center;
	}

	.SimilarItems-collection .priceP {
		width: auto;
		margin: 16px 0px 0 0px;
	}



	/* Buy & Stocklist Button Infos */
	.SimilarItems-collection .buyBut {
		grid-column: 1/2;
		grid-row: 4/5;
		margin-left: 36px;
		width: 216px;
		height: 40px;
		background-color: #363636;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.SimilarItems-collection .buyBut p {
		margin: 0;
	}

	.SimilarItems-collection .buyBut:hover {background-color: #363636;}
	.SimilarItems-collection .buyBut:active {background-color: #363636;}
	.SimilarItems-collection .buyBut:hover p {margin-left: 0;}

	.SimilarItems-collection .stocklist {
		grid-column: 1/2;
		grid-row: 3/4;
		margin: 22px 85px;
		font-size: 10px;
		color: #282828;
		letter-spacing: 3px;
		text-align: center;	
		text-transform: uppercase;
		text-decoration: underline;
	}


	/* STOCKLIST OVERLAY */
	.SimilarItems-stocklist-container {
		grid-column: 1/2;
		grid-row: 1/5;
		width: 100%;
		position: relative;
		margin-top: auto;
	}
	
	.SimilarItems-stocklist-container .closeButton {
		height: 29px;
		padding: 12px 0 14px 0;
		display: flex;
		justify-content: center;
	}
	
	.SimilarItems-stocklist-container .closeButton:active div {
		background-color: #FFFFFF;
	}
	
	.SimilarItems-stocklist-container .listButton {
		height: 49px;
		padding: 8px 20px;
		margin-top: 4px;
	}

	.SimilarItems-stocklist-container .listContainer:last-child {
		margin-bottom: 12px;
	}
	
	.SimilarItems-stocklist-container .brand {
		font-size: 16px;
		letter-spacing: 3px;
		text-align: center;
	}
	
	.SimilarItems-stocklist-container .stock {
		font-size: 9px;
		text-align: center;
		margin-top: 3px;
	}
	
	.SimilarItems-stocklist-container .listButton:active .brand {
		color: white;
	}

	.SimilarItems-stocklistOverlay {
		grid-column: 1/2;
		grid-row: 1/5;
		background-color: rgba(0,0,0,0.0);
		z-index: 10;
	}
}
