.RedirectPaypal {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.RedirectPaypal .loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.loading-container p {
	margin-top: 30px;
	font-size: 11px;
}