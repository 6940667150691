.Reset-Password {
	position: relative;
	min-height: 100vh;
}

.Reset-Password h2 {
	font-size: 22px;
	font-weight: 800;
}

.content-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 90px;
	padding-bottom: 270px;
}

.Reset-Password .form {
	display: flex;
	flex-direction: column;
	align-items: center;
}


.Reset-Password input[type=password] {
	border: 0;
	background-color: #F2F2F2;
	height: 47px;
	width: calc(100vw - 40px);
	max-width: 320px;
	border-radius: 13px;
	text-align: center;
	font-weight: 600;
	font-size: 14px;
	color: #282828;
	font-family: 'Montserrat', sans-serif;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #282828;
	opacity: 0.3;
	font-size: 13px;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #282828;
	opacity: 0.3;
	font-size: 13px;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
	color: #282828;
	opacity: 0.3;
	font-size: 13px;
  }

input:focus {
	outline: none;
}


.Reset-Password .password-text-field {
	margin-top: 84px;
}

.Reset-Password .repeat-password-text-field {
	margin-top: 20px;
}

.error-label {
	color: red;
	font-size: 12px;
	margin-top: 50px;
}

.submit-button {
	background-color: #282828;
	color: white;
	height: 47px;
	border-radius: 13px;
	font-size: 13px;
	font-weight: 700;
	width: 169px;
	margin-top: 20px;
	border: none;
	cursor: pointer;
	font-family: 'Montserrat', sans-serif;
	-webkit-appearance: none;
}




/* SUCCESS */
.Reset-Password .check-icon {
	width: 60px;
	height: 45px;
	object-fit: cover;
	-o-object-fit: cover;
	margin-top: 125px;
}

.Reset-Password .success {
	margin-top: 42px;
	font-size: 12px;
	font-weight: 600;
}



@media only screen and (max-width: 580px) {
	.content-container {
		margin-top: 50px;
	}
}