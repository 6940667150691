/* HEADER */
.header-V2-container {
	height: 91px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 94px;
}

.header-V2-container .title {
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.9px;
}

.open-in-app-button {
	border-radius: 7px;
	width: 139px;
	height: 35px;
	font-size: 12px;
	color: white;
	font-weight: 700;
	background: rgb(194,216,229);
	background: linear-gradient(90deg, rgba(194,216,229,1) 0%, rgba(101,173,229,1) 100%);
}

@media only screen and (max-width: 580px) {
	/* HEADER */
	.header-V2-container {
		height: 59px;
		padding: 0 23px;
	}
}