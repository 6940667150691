.TermsOfUse {
	position: relative;
}


.TermsOfUse .terms-content {
	padding: 150px 100px;
	width: 100vw;
}


.TermsOfUse h2 {
	font-size: 25px;
}

.terms-content p {
	text-transform: none;
	font-weight: 500;
	opacity: 0.8;
	margin-top: 30px;
}

.TermsOfUse .info {
	list-style: initial;
}


/* MAIN LISTE */
.liste-container {
	margin-top: 70px;
}

.TermsOfUse ol {
	list-style-position: inside;
}

.TermsOfUse li {
	font-size: 20px;
	font-weight: 700;
	margin-top: 70px;
}

.TermsOfUse ul {
	list-style-position: inside;
	margin-top: 10px;
	list-style: none;
	background-color: rgba(0, 0, 0, 0);
}

.TermsOfUse ul li {
	margin-left: 30px;
	font-size: 16px;
	font-weight: 500;
	margin-top: 20px;
}

.TermsOfUse .inner-num-list {
	padding-left: 60px;
}


@media only screen and (max-width: 1200px) {
	.TermsOfUse .terms-content {
		padding: 100px 40px 200px 40px;
		width: 100vw;
	}
}