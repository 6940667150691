@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
	margin: 0;
	padding: 0;
  box-sizing: border-box;
  background-color: white;
}

html {
	font-family: 'Montserrat', sans-serif;
}

a {
  text-decoration: none;
  color: #282828;
	background-color: rgba(0, 0, 0, 0)
}

button:active {
  color: #282828;
}

/* Older browsers (do now support CSS Environment variables). */
.Home {
	height: 100%;
  }
  
  /* Browsers which partially support CSS Environment variables (iOS 11.0-11.2). */
  @supports (padding-bottom: constant(safe-area-inset-bottom)) {
	.Home {
	  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
	  height: calc(100vh + var(--safe-area-inset-bottom));
	}
  }
  
  /* Browsers which fully support CSS Environment variables (iOS 11.2+). */
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
	.Home {
	  --safe-area-inset-bottom: env(safe-area-inset-bottom);
	  height: calc(100vh + var(--safe-area-inset-bottom));
	}
  }


/* CUSTOM MOBILE NAV */
.MobileHomeNav {
	width: 100vw;
	position: absolute;
	top: 0;
	margin-top: 31px;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0);
}

.MobileHomeNav .brand {
	color: black;
	font-weight: 800;
	font-size: 25px;
	letter-spacing: 3.7px;
	text-align: center;
}

.MobileHomeNav h1 {
	font-weight: 700;
	font-size: 11px;
	letter-spacing: 1.7px;
	text-align: center;
	margin-top: 24px;
	background-color: rgba(0, 0, 0, 0);
}

.MobileHomeNav .women {
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 4px;
	text-align: center;
	margin-top: 52px;
}

.MobileHomeNav .men {
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 4px;
	text-align: center;
	margin-top: 11px;
}


/* IMAGES */
/* Desktop */
.Desktop .header-2 {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	margin-top: 73px;
	z-index: 9;
	background-color: rgba(0, 0, 0, 0);
}
.Desktop h2 {
	font-size: 11px;
	letter-spacing: 6px;
	font-weight: 700;
}

.Desktop .line {
	height: 1px;
	width: 71px;
	background-color: #383838;
	margin-top: 22.5px;
}


.Home .Desktop-images {
	position: absolute;
	width: 100vw;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	padding-left: 27.7vw;
	/* padding-right: 13vw; */
	height: 100vh;
	background-color: rgba(0, 0, 0, 0);
	z-index: 0;
}

.Home .image1 {
	object-fit: contain;
	/* width: 31.944444vw; */
	height: 67.317073vh;
	z-index: 2;
	max-height: 73vh;
	background-color: rgba(0, 0, 0, 0);
}

.Home .image2 {
	object-fit: contain;
	/* width: 39.305556vw; */
	margin-left: -132px;
	height: 82.910156vh;
	/* margin-left: -9.166667vw; */
	z-index: 1;
	max-height: 88vh;
}


/* Mobile */
.Home .mainImage {
	height: 100vh;
	object-fit: cover;
	/* padding-bottom: 0px; */
	width: 100vw;
}

.Home .image {
	position: relative;
}



@media only screen and (max-width: 1290px) {
	.Home {
		height: calc(100vh + 97px);
		position: relative;
	}

	@supports (padding-bottom: constant(safe-area-inset-bottom)) {
		.Home {
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		height: calc(100vh + 97px + var(--safe-area-inset-bottom));
		}
	}
	@supports (padding-bottom: env(safe-area-inset-bottom)) {
		.Home {
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		height: calc(100vh + 97px + var(--safe-area-inset-bottom));
		}
	}
}

@media only screen and (max-width: 775px) {
	.Home {
		height: calc(100vh + 147px);
		position: relative;
	}
		
	@supports (padding-bottom: constant(safe-area-inset-bottom)) {
		.Home {
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		height: calc(100vh + 147px + var(--safe-area-inset-bottom));
		}
	}
	@supports (padding-bottom: env(safe-area-inset-bottom)) {
		.Home {
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		height: calc(100vh + 147px + var(--safe-area-inset-bottom));
		}
	}
}

@media only screen and (max-width: 430px) {
	.Home {
		position: relative;
		height: calc(100vh + 174px);
	}
	
	@supports (padding-bottom: constant(safe-area-inset-bottom)) {
		.Home {
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		height: calc(100vh + 174px + var(--safe-area-inset-bottom));
		}
	}
	@supports (padding-bottom: env(safe-area-inset-bottom)) {
		.Home {
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		height: calc(100vh + 174px + var(--safe-area-inset-bottom));
		}
	}
}
.Header {
	background-color: rgb(0, 0, 0, 0);
	position: fixed;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: 160px;
	margin: 45px 0 0 45px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	z-index: 10;
}

.Header div {background-color: rgb(0, 0, 0, 0);}

.Header .brand {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	font-size: 21px;
	font-weight: 800;
	color: #282828;
	letter-spacing: 3px;
	background-color: rgba(0, 0, 0, 0);
}

.Header .choose-container {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: 54px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.Header .choose-container .gender {
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 5px;
	color: #282828;
}

.headerFooter {z-index: 10;}


.headerFooter div {background-color: rgb(0, 0, 0, 0)}

.Terms {
	position: fixed;
	left: 0;
	bottom: -11px;
	margin: 45px;
	display: flex;
	justify-content: space-between;
	transform-origin: top left; /* IE 9 */ /* Safari 3-8 */
	transform: rotate(-90deg);
	flex-wrap: wrap;
	z-index: 10;
}

.Terms .info {
	margin-right: 40px;
	font-size: 9px;
	font-weight: 600;
	letter-spacing: 8.4px;
	color: #282828;
}



@media only screen and (max-height: 950px) {
	.Terms .info {
		letter-spacing: 5.5px;
	}
}

@media only screen and (max-height: 800px) {
	.Terms .info {
		margin-right: 20px;
		letter-spacing: 3px;
	}
}

@media only screen and (max-height: 635px) {
	.Terms .info {
		letter-spacing: 0px;
	}
}





/* SOZIAL */
.sozial-conatiner {
	position: fixed;
	bottom: 45px;
	left: 101px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	z-index: 10;
}


.sozialIcon-container {
	display: grid;
	grid-template-rows: 13px 16px 13px;
}

.sozialIcon-container .facebookBut {
	width: 20px;
	height: 20px;
	background-color: rgba(0, 0, 0, 0);
}

.sozialIcon-container .facebookIcon {
	width: auto;
	height: 15px;
}

.sozialIcon-container .instagramBut {
	width: 20px;
	height: 20px;
	grid-row: 3/4;
	background-color: rgba(0, 0, 0, 0);
}

.sozialIcon-container .instagramIcon {
	width: auto;
	height: 15px;
}



.appStore {
	display: flex;
	align-items: flex-end;
	margin-left: 45px;
}

.appStore .apple {
	width: 13px;
	height: 13px;
	background-color: rgba(0, 0, 0, 0);
}

.appStore p {
	font-size: 9px;
	font-weight: 500;
	letter-spacing: 2px;
	margin-left: 11px;
}
.wrapper {
	position: relative;
}

.CookieAlert {
	width: 255px;
	max-height: auto;
	position: fixed;
	overflow: hidden;
	bottom: 29px; right: 29px;
	padding: 27px 27px 21px 27px;
	background-color: white;
	box-shadow: 0 20px 40px 0 rgba(0,0,0,0.14);
	transition: max-height 0.8s ease-in-out;
	z-index: 200;
}

.CookieAlert div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.CookieAlert .title {
	width: 100%;
	text-align: left;
	font-weight: 800;
	letter-spacing: 1.2px;
	font-size: 12px;
}

.CookieAlert .contentText {
	width: 100%;
	text-align: left;
	font-weight: 500;
	letter-spacing: 1px;
	font-size: 11px;
	text-transform: none;
	line-height: 16px;
	margin-top: 12px;
}

.CookieAlert .acceptButton {
	width: 100%;
	height: 39px;
	font-weight: 800;
	letter-spacing: 1.2px;
	font-size: 11px;
	color: white;
	background-color: #2F2F2F;
	margin-top: 43px;
	transition: .4s ease-in-out;
}

.CookieAlert .acceptButton:hover {
	color: #282828;
	background-color: white;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.10);
	/* transform: translateY(-6px); */
}

.CookieAlert .individuellButton {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	font-weight: 700;
	letter-spacing: 1.2px;
	font-size: 11px;
	margin-top: 21px;
}



/*  */

.CookieAlert .contentInduviduellText {
	width: 100%;
	text-align: left;
	text-transform: none;
	font-weight: 500;
	letter-spacing: 1px;
	font-size: 10px;
	margin-top: 12px;
}

.CookieAlert .saveButton {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	font-weight: 800;
	letter-spacing: 2px;
	font-size: 11px;
	margin-top: 35px;
}

.CookieAlert .acceptAllButton {
	width: 100%;
	height: 39px;
	font-weight: 800;
	letter-spacing: 1.2px;
	font-size: 11px;
	color: white;
	background-color: #2F2F2F;
	margin-top: 18px;
	transition: .4s ease-in-out;
}

.CookieAlert .acceptAllButton:hover {
	color: #282828;
	background-color: white;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.10);
}

/* Checkbox */
.CookieAlert .checkbox {
	margin-top: 27px;
	align-self: flex-start;
	display: flex;
	align-items: center;
	justify-content: center;
}

.CookieAlert .checkbox .circle {
	width: 15px;
	min-width: 15px;
	height: 15px;
	border: 2px solid #282828;
	border-radius: 50%;
	transition: .13s ease-in-out;
}

.CookieAlert .checkbox .title {
	margin-left: 12px;
	font-size: 11px;
	font-weight: 700;
}



@media only screen and (max-width: 570px) { 
	.CookieAlert {
		/* position: -webkit-sticky;
		position: sticky;
		width: 100vw;
		bottom: 0; right: 0; */
		bottom: 20px; right: 20px;
	}
}

.Footer {
	bottom: 0;
	/* margin-bottom: -97px; */
}

@supports (padding-bottom: constant(safe-area-inset-bottom)) {
	.Footer {
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		bottom: calc(var(--safe-area-inset-bottom));
	}
}
@supports (padding-bottom: env(safe-area-inset-bottom)) {
	.Footer {
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		bottom: calc(var(--safe-area-inset-bottom));
	}
} 

.Footer footer {
	/* background-color: rebeccapurple; */
	width: 100vw;
	height: 97px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 07.083333vw;
}


/* Terms */
.Footer .terms {
	position: relative;
	height: 41px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* flex-wrap: wrap; */
	background-color: white;
}

.Footer .terms .footerLinks {
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 3px;
	margin-right: 30px;
}

.Footer .terms :last-child {margin-right: 0px;}

/* Promo */
.Footer .promo {
	display: flex;
	align-items: center;
	background-color: white;
	height: 41px;
}

.Footer .socialList {
	margin-right: 39px;
	background-color: white;
}

.Footer .socialList .icon {
	width: 17px;
	height: 17px;
	background-color: white;
}

.Footer .socialList .instagram {
	margin-left: 15px;
}

.Footer .appStoreBut {
	width: 275px;
	height: 41px;
	background-color: #2C2C2C;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Footer .appStoreBut p {
	color: white;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 3.9px;
}

.Footer .appStoreBut .apple {
	margin-right: 11px;
	width: 11px;
	height: 13px;
	background-color: rgba(0, 0, 0, 0)
}




/* Tablet */
@media only screen and (max-width: 1290px) {

}

@media only screen and (max-width: 1200px) {
	.Footer footer {padding: 0px 40px;}
	.Footer .terms .footerLinks {margin-right: 29px;}
	.Footer .terms :last-child {margin-right: 0px;}

	.Footer .socialList {margin-right: 27px}
	.Footer .socialList .instagram {margin-left: 13px;}
	.Footer .terms .footerLinks {font-size: 9px;}
}

@media only screen and (min-height: 1200px) and (max-width: 1290px) {

}


/* Mobile */
@media only screen and (max-width: 1000px) {
	.Footer .terms {align-items: flex-end;}
	.Footer .promo {align-items: flex-end;}

	.Footer .terms :first-child {
		position: absolute;
		top: 0;
	}

	.Footer .terms .footerLinks {
		letter-spacing: 1px;
	}
}

/* @media only screen and (max-width: 775px) { */
@media only screen and (max-width: 800px) {
	.Footer footer {
		height: 147px;
		align-items: flex-start;
		padding: 20px 20px 0 20px;
	}

	.Footer .socialList {margin-right: 0;}
	.Footer .socialList .instagram {margin-left: 20x;}

	.Footer .appStoreBut {
		position: absolute;
		width: auto;
		bottom: 20px; left: 20px; right: 20px;
	}

	.Footer .terms .footerLinks {
		letter-spacing: 1px;
	}
}

@media only screen and (max-width: 430px) {
	.Footer .terms {align-items: flex-end;}
	.Footer .promo {align-items: flex-end;}

	.Footer footer {
		height: 174px;
	}

	.Footer .terms {
		align-items: flex-start;
		flex-direction: column;
		height: 63px;
	}

	.Footer .terms .footerLinks {
		font-weight: 600;
		margin-right: 0;
		letter-spacing: 2px;
	}

	.Footer .terms :first-child {
		position: relative;
	}

	.Footer .promo {
		height: 63px;
	}
}

.Newsletter {
	z-index: 110;
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	justify-content: center;
	align-items: center;
}

.Newsletter .alert {
	z-index: 110;
	max-width: 41.375rem;
	margin: 0 0.875rem;
	width: 100%;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #EFEFEF;
	transition: 3.1s;
}

.Newsletter .title {
	font-weight: 800;
	font-size: 1.06rem;
	margin-top: 2.3125rem;
	letter-spacing: 0.5px;
}

.Newsletter .text {
	font-weight: 600;
	/* font-size: 0.8125rem; */
	font-size: 11px;
	margin: 2.625rem 1.5625rem 0 1.5625rem;
	letter-spacing: 1px;
	text-align: center;
}

.Newsletter .textField {
	font-weight: 500;
	font-size: 0.8125rem;
	letter-spacing: 1.5px;
	max-width: 26.44rem;
	/* width: 100%; */
	width: calc(100% - 1.5625rem - 1.5625rem);
	margin: 1.875rem 1.5625rem 0 1.5625rem;
	border: none;
	text-align: center;
	padding: 11px;
	font-family: 'Montserrat', sans-serif;
	outline: none;
	text-transform: uppercase;
}

.Newsletter .errMessage {
	font-weight: 700;
	font-size: 0.5625rem;
	margin: 0.25rem 1.5625rem 0 1.5625rem;
	letter-spacing: 3px;
	text-align: center;
	text-transform: uppercase;
	color: #D34B4B;
}

.Newsletter .nextStep {
	font-weight: 800;
	font-size: 0.5rem;
	margin: 0.8125rem 1.5625rem 0 1.5625rem;
	letter-spacing: 1.1px;
	text-align: center;
	text-transform: uppercase;
}

.Newsletter .subBut {
	font-weight: 800;
	font-size: 0.8125rem;
	margin: 3.1875rem 0 2.0625rem;
	background-color: rgba(0, 0, 0, 0);
}




.Newsletter .overlay {
	z-index: 109;
	position: absolute;
	left: 0; right: 0; top: 0; bottom: 0;
	background-color: rgba(0, 0, 0, 0.29);
}



@media only screen and (max-width: 420px) {
	.Newsletter .title {
		margin-top: 1.25rem;
	}
	.Newsletter .text {
		margin-top: 1.5625rem;
	}
	
	.Newsletter .textField {
		margin-top: 1.5625rem;
	}
	
	.Newsletter .errMessage {

	}
	
	.Newsletter .nextStep {

	}
	
	.Newsletter .subBut {
		margin-bottom: 1.25rem;
	}
}
.loadingAlert {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0);
}

.loadingAlert p {
	font-weight: 600;
	font-size: 10px;
	color: #282828;
	letter-spacing: 2px;
}

.comingsoon-div {
	position: absolute;
	top: 0; right: 0; left: 0; bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.comingsoon-div p {
	font-size: 12px;
	letter-spacing: 3px;
	font-weight: 600;
}
.collection {
	padding: 10.8125rem 07.083333vw 175px 07.083333vw;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 29.861111vw;
	grid-gap: 07.083333vw;
	z-index: 2;
}
/* .img {
	background-color: black;
	box-shadow: 0px 02.083333vw 03.472222vw rgba(0, 0, 0, 0.1);
} */

.imgContainer {
	background-color: white;
	box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
}

.image {
	object-fit: cover;
	width: 100%;
	height: 29.861111vw;
	object-position: center;
	image-rendering: auto;
	image-rendering: crisp-edges;
	-ms-interpolation-mode: nearest-neighbor;
	    image-rendering: -moz-crisp-edges;
	    image-rendering: pixelated;
	image-rendering: inherit;
}





/* TALET */
@media only screen and (max-width: 1200px) {
	.collection {
		padding: 128px 0 125px 0;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 61.66vw;
		grid-gap: 5px;
		background-color: white;
	}

	.imgContainer {box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);}

	.image {height: 61.66vw;}
}

/* PHONE */
@media only screen and (max-width: 750px) {
	.collection {
		padding: 70px 0 210px 0;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 61.66vw;
		grid-gap: 5px;
		box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
	}

	.imgContainer {box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);}

	.image {height: 61.66vw;}
}
.biggerNav-container {
	position: relative;
	z-index: 10;
	width: 100%;
	position: fixed;
	display: flex;
	align-items: center;
	transition: .3s;
	-webkit-transition: .3s;
	backdrop-filter: blur(12px);
	-webkit-backdrop-filter: blur(12px);
}



@-webkit-keyframes colorchange {
	0%   {background-color: white}
	100% {background-color: rgba(255, 255, 255, 0.93)}
}



@keyframes colorchange {
	0%   {background-color: white}
	100% {background-color: rgba(255, 255, 255, 0.93)}
}
  
  @-webkit-keyframes colorchangeBack { 
	/* 0%   {background-color: rgba(238, 238, 238, 0.9)} */
	0%   {background-color: rgba(255, 255, 255, 0.93)}
	100% {background-color: white}
  }
  
  @keyframes colorchangeBack { 
	/* 0%   {background-color: rgba(238, 238, 238, 0.9)} */
	0%   {background-color: rgba(255, 255, 255, 0.93)}
	100% {background-color: white}
  }
  

  @-webkit-keyframes blurChange {
	0% {
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px)
	}

	100% {
		backdrop-filter: blur(12px);
		-webkit-backdrop-filter: blur(9px)
	}
  }
  

  @keyframes blurChange {
	0% {
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px)
	}

	100% {
		backdrop-filter: blur(12px);
		-webkit-backdrop-filter: blur(9px)
	}
  }
	
  @-webkit-keyframes blurChangeBack {
	0% {
		backdrop-filter: blur(12px);
		-webkit-backdrop-filter: blur(9px)
	}
	
	100% {
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px)
	}
  }
	
  @keyframes blurChangeBack {
	0% {
		backdrop-filter: blur(12px);
		-webkit-backdrop-filter: blur(9px)
	}
	
	100% {
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px)
	}
  }


.biggerNav-container nav {
	margin: 0px 07.083333vw;
	height: 24px;
	width: 85.833334vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0);
}

.biggerNav-container nav .brand {
	text-decoration: none;
	font-size: 20px;
	letter-spacing: 3px;
	background-color: rgba(0, 0, 0, 0);
	font-weight: 800
}

.biggerNav-container nav ul {
	height: 100%;
	/* max-width: 347px; */
	/* max-width: 218px; */
	/* width: 25vw; */
	width: 13.625rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	background-color: rgba(0, 0, 0, 0);
}

.biggerNav-container nav ul li {
	background-color: rgba(0, 0, 0, 0);
	height: 100%;
	display: flex;
	align-items: center;

}


.biggerNav-container .chooseGender {
	display: flex;
	align-items: center;
	height: 100%;
	color: #282828;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 2px;
	font-weight: 600;
}





/* *** PHONE *** */
.phoneNav-container {
	display: none;
	position: fixed;
	top: 0; left: 0; right: 0;
	height: 48px;
}

.phoneNav-container nav {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	position: relative;
}

/* Icon */
.phoneNav-container .menuIcon {
	background-color: white;
	position: absolute;
	width: 57px;
	height: 100%;
	left: 0;
}

.phoneNav-container .menuIcon .icon-container {
	background-color: white;
	width: 21px;
	height: 10px;
	margin-left: 18px;
}

.phoneNav-container .menuIcon .icon-container div {
	display: absolute;
	width: 100%;
	height: 2px;
	background-color: #282828;
	transition: .4s;
}

.phoneNav-container .menuIcon .topLayer {
	margin-top: 0px;
}

.phoneNav-container .menuIcon .bottomLayer {
	margin-top: 6px;
}

.phoneNav-container .menuIcon .topLayer-open {
	transform: rotate(135deg);
	margin-top: 5.25px;
}

.phoneNav-container .menuIcon .bottomLayer-open {
	transform: rotate(-135deg);
	margin-top: -2px;
}


/* Brand */
.phoneNav-container .brand {
	font-size: 15px;
	font-weight: 700;
	color: #282828;
	letter-spacing: 5.7px;
}

/* SHARE */
.phoneNav-container .share {
	position: absolute;
	background-color: orange;
	width: 48px;
	height: 100%;
	top: 0px; right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.phoneNav-container .share .button {
	width: 100%;
	height: 100%;
}

.phoneNav-container .share img {
	object-fit: contain;
}




/* Overlay Menu */
.phoneMenuOverlay {
	z-index: 100;
	position: fixed;
	overflow: hidden;
	width: 100%;
	/* height: 122px; */
	top: 48px;
	transition: max-height 0.52s;
	transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
}

.phoneMenuOverlay .background {
	width: 100%;
	height: 122px;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 36px;	
	position: relative;
	z-index: 100;
}


.phoneMenuOverlay .background .text {
	position: relative;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 5px;
	transition: 0.52s;
	transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.overlayBack {
	position: fixed;
	width: 100%;
	z-index: 99;
	transition: background-color .3s;
	-webkit-transition: background-color .3s;
}

@-webkit-keyframes overlayAlphaChange {
	0%   {background-color: rgba(0, 0, 0, 0)}
	100% {background-color: rgba(0, 0, 0, 0.2)}
}

@keyframes overlayAlphaChange {
	0%   {background-color: rgba(0, 0, 0, 0)}
	100% {background-color: rgba(0, 0, 0, 0.2)}
}

@-webkit-keyframes overlayAlphaChangeBack {
	0%   {background-color: rgba(0, 0, 0, 0.2)}
	100% {background-color: rgba(0, 0, 0, 0)}
}

@keyframes overlayAlphaChangeBack {
	0%   {background-color: rgba(0, 0, 0, 0.2)}
	100% {background-color: rgba(0, 0, 0, 0)}
}





/* Tablet */
@media only screen and (max-width: 1200px) {
	.biggerNav-container nav {
		width: 100vw;
		margin: 0;
		padding: 0px 40px;
	}
}

/* Phone */
@media only screen and (max-width: 750px) {
	.biggerNav-container {
		display: none;
	}

	.phoneNav-container {
		z-index: 101;
		display: inline;
	}
}


.Filter{
	z-index: 9;
	top: 8.4375rem; left: 0;
	position: absolute;
	display: flex;
	align-items: center;
}

.Filter .shortBy {
	z-index: 11;
	padding-left: 07.083333vw;
	letter-spacing: 4px;
}

.Filter .firstPart {
	font-weight: 600;
	background-color: rgba(0, 0, 0, 0);
}

.Filter .lastPart {
	letter-spacing: 3px;
	margin-left: 10px;
	font-weight: 400;
	background-color: rgba(0, 0, 0, 0);
}

.Filter .selection {
	z-index: 10;
	display: flex;
	transition: 0.6s;
	transform: translateX(-4000px);
}

.Filter .selection .filterName {
	color: #919191;
	margin-left: 30px;
	letter-spacing: 2px;
	transition: 0.3s;
	-webkit-transition: 0.3s;
}

.Filter .selection .filterName:hover {
	color: #282828;
}







/* Phone Sort overlay */
.overlay-container div {background-color: white;}
.overlay-container {
	z-index: 99;
	position: fixed;
	width: 100vw;
	height: 100vh;
	bottom: 0;
	/* overflow: hidden; */
	-webkit-animation-duration: .4s;
	animation-duration: .4s;
}

.overlay-container .menu-container {
	position: absolute;
	width: 100vw;
	bottom: 0;
	transition: max-height 0.4s;
	transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
}

.overlay-container .closeButton {
	background-color: white;
	position: absolute;
	right: 0; top: 0;
	padding: 15px;
}

.overlay-container .closeButton .icon {
	background-color: white;
	width: 15px;
	height: 15px;
}


.overlay-container .sortChoice {
	background-color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	margin: 50px 0;
	height: 114px;
}

.overlay-container .sortChoice .sortName {
	background-color: white;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 2px;
	color: #919191;
}


 @-webkit-keyframes openMenu {
	0% {
		background-color: rgba(0, 0, 0, 0);
		backdrop-filter: blur(0);
		-webkit-backdrop-filter: blur(0);
	}
	100% {
		background-color: rgba(0, 0, 0, 0.47);
		backdrop-filter: blur(16px);
		-webkit-backdrop-filter: blur(16px);
	}
  }
  
  @keyframes openMenu {
	0% {
		background-color: rgba(0, 0, 0, 0);
		backdrop-filter: blur(0);
		-webkit-backdrop-filter: blur(0);
	}
	100% {
		background-color: rgba(0, 0, 0, 0.47);
		backdrop-filter: blur(16px);
		-webkit-backdrop-filter: blur(16px);
	}
  }


  @-webkit-keyframes closeMenu {
	0% {
		background-color: rgba(0, 0, 0, 0.47);
		backdrop-filter: blur(16px);
		-webkit-backdrop-filter: blur(16px);
	}
	100% {
		background-color: rgba(0, 0, 0, 0);
		backdrop-filter: blur(0);
		-webkit-backdrop-filter: blur(0);
	}
  }

  @keyframes closeMenu {
	0% {
		background-color: rgba(0, 0, 0, 0.47);
		backdrop-filter: blur(16px);
		-webkit-backdrop-filter: blur(16px);
	}
	100% {
		background-color: rgba(0, 0, 0, 0);
		backdrop-filter: blur(0);
		-webkit-backdrop-filter: blur(0);
	}
  }


  









/* Tablet */
@media only screen and (max-width: 1200px) {
	.Filter {
		top: 99px; left: 0;
	}

	.Filter .shortBy {
		padding-left: 40px;
	}
}

/* Phone */
@media only screen and (max-width: 750px) {
	.Filter { 	
		top: 48px; left: 0;
		background-color: rgba(0, 0, 0, 0);
	}

	.Filter .shortBy {
		padding: 0 10px 0 18px;
		background-color: rgba(0, 0, 0, 0);
	}

	.Filter .shortBy .firstPart {
		background-color: rgba(0, 0, 0, 0);
		color: #585858;
	}
}


.preview {
	position: relative;
}

.preview-container {
	background-color: #F7F7F7;
	display: flex;
	width: 100%;
	/* height: 100vh;    other scroll!!    */
	/* justify-content: stretch; */
	height: auto;
}

button {
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
	font-family: 'Montserrat', sans-serif;
	-webkit-tap-highlight-color: transparent;
}


/* .footer {
	width: 100vw;
	height: 10px;
	background-color: red;
	margin-top: 48px;
} */


@media only screen and (max-width: 1290px) {
	/* .preview-container {
		flex-direction: column;
		height: auto;
	} */
}

/* phone */
@media only screen and (max-width: 934px) {
	.preview-container {
		flex-direction: column;
		height: auto;
	}
}

@media only screen and (min-height: 1200px) and (max-width: 1290px) {
	.preview-container {
		flex-direction: column;
		height: auto;
	}
}

/* Overlay */
.Share-overlay {
	display: none;
	z-index: 110;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0; right: 0; bottom: 0; left: 0;
}

@-webkit-keyframes shareColorchange {
  0%   {background: rgba(0,0,0,0.0);}
  100% {background: rgba(0,0,0, .72);}
}

@keyframes shareColorchange {
  0%   {background: rgba(0,0,0,0.0);}
  100% {background: rgba(0,0,0, .72);}
}

@-webkit-keyframes shareColorchangeBack {
	0%   {background: rgba(0,0,0,0.72);}
	100% {background: rgba(0,0,0,0.0);}
}

@keyframes shareColorchangeBack {
	0%   {background: rgba(0,0,0,0.72);}
	100% {background: rgba(0,0,0,0.0);}
}

@-webkit-keyframes blurChange {
	0%   {-webkit-backdrop-filter: blur(0px);backdrop-filter: blur(0px);}
	100% {-webkit-backdrop-filter: blur(4px);backdrop-filter: blur(4px);}
}

@keyframes blurChange {
	0%   {-webkit-backdrop-filter: blur(0px);backdrop-filter: blur(0px);}
	100% {-webkit-backdrop-filter: blur(4px);backdrop-filter: blur(4px);}
}
  
@-webkit-keyframes blurChangeBack {
	0%   {-webkit-backdrop-filter: blur(4px);backdrop-filter: blur(4px);}
	100% {-webkit-backdrop-filter: blur(0px);backdrop-filter: blur(0px);}
}
  
@keyframes blurChangeBack {
	0%   {-webkit-backdrop-filter: blur(4px);backdrop-filter: blur(4px);}
	100% {-webkit-backdrop-filter: blur(0px);backdrop-filter: blur(0px);}
}
  

/* Card */
.Share-container {
	width: 85%;
	max-width: 483px;
	height: 372px;
	background: #F6F6F6;
	display: grid;
	grid-template-columns: auto 89px;
	grid-template-rows: auto 72px;
}

@-webkit-keyframes opacityChange {
	0%   {opacity: 0;}
	100% {opacity: 1;}
  }

@keyframes opacityChange {
	0%   {opacity: 0;}
	100% {opacity: 1;}
  }
  
  @-webkit-keyframes opacityChangeBack {
	0%   {opacity: 1;}
	100% {opacity: 0;}
  }
  
  @keyframes opacityChangeBack {
	0%   {opacity: 1;}
	100% {opacity: 0;}
  }

p { 
	text-transform: uppercase; 
	background-color: rgba(0,0,0,0.0);
}

.Share-container div { background-color: rgba(0,0,0,0.0); }


/* Sozial Buttons */
.Share-container .sozialButs {
	grid-column: 1/2;
	grid-row: 1/2;
	padding: 65px 0 31px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between
}

.Share-container .sozialButs button {
	background-color: white;
	height: 50px;
	text-align: right;
	padding-right: 21px;
	box-shadow: 20px 20px 40px rgba(0,0,0, .08);
	transition: .5s;
}

.Share-container .sozialButs button:hover {
	transform: translateY(-2px)
}


.Share-container .sozialButs button .icon {
	background: rgba(0,0,0,0.0);
	width: 20px;
	height: 17px; 
	color: #282828;
}


/* Title */
.Share-container .title-container {
	grid-column: 2/3;
	grid-row: 1/3;
	padding: 23px 31px 0 39px;
}	

.Share-container .title {
	text-align: center;
	font-weight: 700;
	font-size: 24px;
	line-height: 40px;
	color: #282828;
}	


/* Cancel Button */
.Share-container .cancelBut {
	background-color: rgba(0,0,0,0.0);
	position: relative;
	grid-column: 1/3;
	grid-row: 2/3;
}

.Share-container .cancelBut p {
	position: absolute;
	right: 89px;
	top: 19px;
	font-weight: 600;
	font-size: 15px;
	color: #3FA1EC;
}




/* Link Copied Alert */
.linkCopiedAlert {
	position: fixed;
	background-color: #282828;
	width: 100vw;
	height: 34px;
	bottom: -34px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.35s ease-in-out;
	color: white;
	font-size: 10px;
	font-weight: 700;
	z-index: 50;
}

.cardCollection-container {
	z-index: 3;
	/* overflow: auto; */
	background-color: rgba(0,0,0,0.0);
	width: 33.2vw;
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* Title */
.cardCollection-container .title1 {
	font-size: 15px;
	font-weight: 800;
	letter-spacing: 3.5px;
	margin: 45px 0 50px 0;
	background-color: rgba(0,0,0,0.0)
}

.cardCollection-container p {background-color: rgba(0,0,0,0.0)}



/* ___________ DESKTOP ___________ */

/* Card */
.Card {
	display: grid;
	padding: 40px 0;
	position: relative;
	background-color: rgba(0, 0, 0, 0);
	transition: all .4s;
}

/* Background */
.Card .background {
	border-radius: 15px;
	background-color: white;
	grid-row: 1/6;
	grid-column: 1/2;
	box-shadow: 0 20px 60px rgba(0,0,0,.04)
}

/* Item Image */
.Card .image-container {
	grid-row: 1/2;
	grid-column: 1/2;
	margin: 23px 23px 23px 23px;
	background-color: rgba(0, 0, 0, 0);	
	box-shadow: 0 20px 50px 0 rgba(0,0,0,.07);
}

.Card .image-container img {
	/* object-fit: contain; */
	border-radius: 12px;
}



/* Info Text */
.Card .info-container {
	grid-column: 1/2;
	grid-row: 2/3;
	display: flex;
	flex-direction: column;
	margin: 0px 23px;
	text-transform: uppercase;
	background-color: rgba(0,0,0,0.0);
}

.Card .info-container p {
	color: #282828;
	width: 100%;
	text-align: left;
	z-index: 1;
}

.Card .info-container .brand {
	font-size: 16px;
	font-weight: 800;
	letter-spacing: 0px;
}

.Card .info-container .title {
	font-weight: 600;
	letter-spacing: 0px;
	margin: 5px 0 0 0;
	font-size: 10px;
}

/* Price */
.Card .info-container .price {
	font-weight: 700;
	font-size: 11px;
	letter-spacing: 1px;
	background-color: rgba(0,0,0,0.0);
}


/* MORE INFOS */
.more-infos-container {
	margin: 23px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.more-info {
	width: calc(100% / 2 - 15px);
	max-width: calc(100% / 2 - 15px);
	display: flex;
	flex-direction: column;
}

.more-info .title {
	font-size: 10px;
	font-weight: 600;
}

.more-info .text {
	font-size: 13px;
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: inherit;
}


/* ALTERNATIV & BUY */
.Card .button-container {
	grid-column: 1/2;
	grid-row: 3/4;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: rgba(0, 0, 0, 0);
}

.Card .alternativ-link {
	width: calc(100% - 46px);
}

.Card .alternativ-button {
	height: 36px;
	width: 100%;
	background-color: #ECECEC;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 11px;
	font-weight: 700;
}

/* Buy */
.Card .buyBut {
	margin-top: 14px;
	border-radius: 8px;
	width: calc(100% - 46px);
	height: 38px;
	background-color: #363636;
	-webkit-tap-highlight-color: #4e4e4e;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.2s cubic-bezier(0.16, 0.08, 0.355, 1);
	font-size: 13px;
	font-weight: 700;
	color: #FFFFFF;
	letter-spacing: 0.3px;
}

.Card .buyBut:hover {background-color: #1d1d1d;}
.Card .buyBut:active {background-color: #1d1d1d;}







/* ___________ TABLET ___________ */
@media only screen and (max-width: 1290px) {
	.cardCollection-container {
		width: 44.55vw;
		padding-top: 72px;
	}

	.Card .buyBut:hover {background-color: #363636;}
	.Card .buyBut:active {background-color: #363636;}


}



@media only screen and (min-width: 745px) and (max-width: 934px) {
	.cardCollection-container {
		width: 100vw;
		z-index: 9;
		background-color: #F7F7F7;
		padding: 0px 73px; 
		display: flex;
		flex-direction: column;
	}

	.Card {
		width: 100%
	}


	.Card .background {
		margin-top: 50px;
		grid-column: 1/3;
		grid-row: 1/3;
	}
	
	
	/* Item Image */
	.Card .image-container {
		grid-row: 1/2;
		grid-column: 1/2;
		margin: 0 60px 39px 60px;
	}
	
	
	
	/* Info Text */
	.Card .info-container {
		grid-column: 2/3;
		grid-row: 1/2;
		margin: 83px 60px 25px 0;
	}
	
	.Card .info-container p {
		color: #282828;
		width: 100%;
		text-align: left;
	}
	
	.Card .info-container .brand {
		font-size: 20px;
		font-weight: 700;
		letter-spacing: 6px;
	}
	
	.Card .info-container .title {
		font-weight: 500;
		letter-spacing: 5px;
		margin: 11px 0 0 0;
		font-size: 10px;
	}
	
	/* Price */
	.Card .info-container .priceP {margin-top: 20px;}
	
	.Card .info-container .price {
		font-size: 13px;
		letter-spacing: 2px;
	}
	
	.Card .info-container .priceInSale {
		margin-top: 22px;
	}
	
	.Card .info-container .salePrice {
		font-size: 13px;
		letter-spacing: 2px;
	}
	

	/* Buy & Alternativ*/
	.Card .button-container {
		grid-column: 1/3;
		grid-row: 2/3;
		align-items: baseline;
		flex-direction: row;
		padding: 0 60px;
	}

	.alternativ-link {
		order: 2;
	}
	.alternativ-button {
		/* width: calc(30% - 50px); */
		/* margin-left: 50px; */
	}
	.Card .buyBut {
		order: 1;
		margin: 0;
	}

}









@media only screen and (max-width: 744px) {
	.cardCollection-container {
		width: 100vw;
		z-index: 9;
		background-color: #F7F7F7;
		padding: 0px 73px; 
		display: flex;
		flex-direction: column;
	}


	.Card .background {
		margin-top: 45px;
	}

	/* Image */
	.Card .image-container {
		margin: 0 36px 36px 36px;
	}


	/* Item Infos */
	.Card .info-container {
		margin: 0px;
		display: flex;
		flex-direction: column;
		padding: 0 36px;
	}

	.Card .info-container p {
		text-align: center;
	}

	.Card .info-container .brand {
		font-weight: 800;
		font-size: 16px;
		color: #282828;
		letter-spacing: 4px;
	}

	.Card .info-container .title {
		width: auto;
		margin-top: 6px;
		font-size: 9px;
		color: #282828;
		letter-spacing: 3px;
		padding: 0;
	}


	/* Buy & Stocklist Button Infos */
	.Card .buyBut {
		height: 40px;
		background-color: #363636;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.Card .more-info .title {
		text-align: left;
	}

}




@media only screen and (min-height: 1200px) and (max-width: 1290px) {
	.cardCollection-container {
		width: 100%;
		z-index: 9;
		/* background-color: rgba(230, 230, 230, 0.96); */
		background-color: #F7F7F7;
		/* backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px); */
		padding-bottom: 50px; 
	}
}
/* 
@media only screen and (max-width: 1200px) {
	.cardCollection-container {
		padding-bottom: 50px;
	}
}

@media only screen and (max-width: 750px) {
	.cardCollection-container {
		padding-bottom: 50px;
	}
} */

/*  ---------- MAIN Image ---------- */
.mainImage-container {
	z-index: 1;
	margin-left: 19.44vw;
	margin-left: 22vw;
	width: 47.36vw;
	/* margin-left: 280px; */
	height: 100vh;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: rgba(0,0,0,0.0)
}


.mainImage-container .imageButton {
	width: 47.36vw;
	height: 100%;
	position: relative;
}

.mainImage {
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0;
	object-fit: cover;
	-o-object-fit: cover;
	width: 47.36vw;
	height: 100%;
}






/* ---------- Pointer ---------- */
.pointer-conatiner {
	z-index: 2;
	position: absolute;
	background-color: rgba(0,0,0,0.0);
	min-width: 0;
	max-width: auto;
	height: 30px;
	transition: .5s;
	-webkit-transition: .5s;
	transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
}

.pointer-conatiner img { background-color: rgba(0,0,0,0.0); }

.pointer-conatiner button {
	background-color: rgba(0,0,0,0.0);
	width: 100%;
	height: 100%;
}



.pointer-button-container {
	background-color: rgba(0,0,0,0.0);
	display: grid;
	align-items: center;
	grid-template-columns: 12px 4px 4px minmax(10px, auto);
}


.pointer-triangle {
	background-color: rgba(0,0,0,0.0);
	object-fit: contain;
	grid-column: 1/3;
	grid-row: 1/2;
	z-index: 1;
}

.pointer-circle {
	object-fit: contain;
	grid-column: 2/4;
	grid-row: 1/2;
	z-index: 2;

}

/* Pointer Brand Name */
.pointer-title-container {
	grid-column: 3/5;
	grid-row: 1/2;
	height: 100%;
	padding-left: 4px;
	border-radius: 0px 4px 4px 0px;
	background-color: white;
	display: flex;
	align-items: center;
}

.pointer-title-container p {
	padding: 5px;
	font-weight: 800;
	font-size: 0.8125rem;
	white-space: nowrap;
	text-overflow: ellipsis;
}


/* Share */
.mainImage-container .share {
	position: absolute;
	width: 41px;
	height: 41px;
	background-color: rgba(0, 0, 0, 0);
	z-index: 10;
	top: 20px;
	left: 20px;
}

.mainImage-container .share img {
	background-color: rgba(0, 0, 0, 0);
	opacity: 0.7;
}


/* PRESS ICON */
.mainImage-container .press-icon {
	z-index: 10;
	position: absolute;
	top: 20px;
	right: 20px;
	width: 23px;
    height: 23px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, .9);
	-webkit-animation: pulse 2s infinite;
	        animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
	0% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}



/* Tablet */
@media only screen and (max-width: 1290px) {
	.mainImage-container {
		margin-left: 0vw;
		width: 55.45vw;
	}

	.mainImage {
		width: 55.45vw;
	}
}


/* Phone */
@media only screen and (max-width: 934px) {
	.mainImage-container {
		margin-left: 0vw;
		width: 100vw;
		position: static;
	}

	.mainImage {
		width: 100vw;
	}
}

@media only screen and (min-height: 1200px) and (max-width: 1290px) {
	.mainImage-container {
		margin-left: 0vw;
		width: 100vw;
		position: static;
	}

	.mainImage {
		width: 100vw;
	}
}

.mail {
	position: absolute;
	font-size: 11px;
	letter-spacing: 2px;
	font-weight: 700;
	top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}
.SimilarItems {
	background-color: #F4F4F4;
	min-height: 100vh;
	position: relative;
}

.SimilarItems-collection {
	background-color: rgba(0, 0, 0, 0);
	margin-left: 217px;
	padding: 135px 90px;
	display: flex;
	flex-direction: column;
}



/* --- ITEM --- */
.SimilarItems-collection .item {
	position: relative;
	margin-bottom: 100px;
	display: grid;
	background-color: rgba(0, 0, 0, 0);
}

.SimilarItems-collection :last-child {
	margin-bottom: 0px;
}


/* Background */
.SimilarItems-collection .background {
	grid-column: 1/3;
	grid-row: 2/4;
	background-color: white;
}


/* Images */
.SimilarItems-collection .images {
	grid-column: 1/2;
	grid-row: 1/4;
	padding: 0 60px 60px 60px;
	background-color: rgba(0, 0, 0, 0);
	display: flex; 
	justify-content: space-between;
}

.SimilarItems-collection .images img {
	object-fit: contain;
	box-shadow: 0 20px 50px 0 rgba(0,0,0,0.10);
	background-color: rgba(0, 0, 0, 0);
}


/* Item Infos */
.SimilarItems-collection .itemInfos {
	grid-column: 2/3;
	grid-row: 2/3;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	flex-direction: column;
	margin: 38px 0 54px;
}

.SimilarItems-collection .brand {
	font-weight: 700;
	font-size: 20px;
	color: #282828;
	letter-spacing: 6.5px;
}

.SimilarItems-collection .title {
	margin-top: 11px;
	font-size: 10px;
	color: #282828;
	letter-spacing: 6px;
	padding-right: 45px;
}

.SimilarItems-collection .priceP {margin-top: 20px;}

.SimilarItems-collection .price {
	font-weight: 700;
	font-size: 13px;
	color: #282828;
	letter-spacing: 2px;
}

.SimilarItems-collection .priceInSale {
	font-weight: 600;
	font-size: 10px;
	letter-spacing: 2px;
	text-decoration: line-through;
}

.SimilarItems-collection .salePrice {
	font-weight: 700;
	font-size: 13px;
	color: #282828;
	letter-spacing: 2px;
	text-decoration: none;
	margin-left: 15px;
}


/* Buy & Stocklist Button Infos */
.SimilarItems-collection .action-container {
	grid-column: 2/3;
	grid-row: 3/4;
	padding: 0 73px 60px 0;
	display: flex;
	justify-content: space-between;
}

.SimilarItems-collection .buyBut {
	margin: 0 41px 0 0;
	width: 100%;
	background-color: #363636;
	-webkit-tap-highlight-color: #4e4e4e;
	display: flex;
	align-items: center;
	transition: background-color 0.2s cubic-bezier(0.16, 0.08, 0.355, 1);
}

.SimilarItems-collection .buyBut:hover {
	background-color: #1d1d1d;
	/* background-color: #4A90E2; */
}

.SimilarItems-collection .buyBut:hover p {
	margin-left: 27px;
}

.SimilarItems-collection .buyBut p {
	font-size: 17px;
	font-weight: 700;
	color: #FFFFFF;
	letter-spacing: 3px;
	text-transform: uppercase;
	margin-left: 20px;
	transition: 0.3s;
}

.SimilarItems-collection .buyBut .soldOut {
	font-size: 8px;
	letter-spacing: 1px;
	background-color: rgba(0, 0, 0, 0);
}

.SimilarItems-collection .stocklist {
	font-weight: 600;
	font-size: 10px;
	color: #919191;
	letter-spacing: 3.5px;
	text-transform: uppercase;
}


/* STOCKLIST OVERLAY */
.SimilarItems-stocklist-container {
	grid-column: 2/3;
	grid-row: 1/4;
	position: absolute;
	bottom: 0; right: 0;
	width: 176px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	background-color: #282828;
	overflow-y: hidden;
	transition-property: all;
	transition: .6s;
	transition-timing-function: cubic-bezier(.2,.64,.39,.99);
	z-index: 11;
}

.SimilarItems-stocklist-container .listContainer { background-color: rgba(0, 0, 0, 0)}

.SimilarItems-stocklist-container .closeButton {
	background-color: rgba(0, 0, 0, 0);
	height: 23px;
	width: 100%;
	padding: 13px 0 7px 17px;
	text-align: center;
}

.SimilarItems-stocklist-container .closeButton div {
	background: #919191;
	width: 20px;
	height: 3px;	
	
	transition: background-color .3s;
	-webkit-transition: background-color .3s;
	-webkit-tap-highlight-color: #FFFFFF;
}

.SimilarItems-stocklist-container .closeButton:hover div {
	background-color: #FFFFFF;
}

.SimilarItems-stocklist-container .listButton {
	background-color: rgba(0, 0, 0, 0);
	width: 100%;
	height: 42px;
	padding: 7px 17px;
	margin-top: 6px;
}

.SimilarItems-stocklist-container .listContainer:last-child {
	margin-bottom: 13px;
}


.SimilarItems-stocklist-container .brand {
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 2px;
	text-align: left;
}

.SimilarItems-stocklist-container .stock {
	font-weight: 700;
	font-size: 8px;
	letter-spacing: 1px;
	text-align: left;
}

.SimilarItems-stocklist-container .listButton:hover .brand {
	color: white;
}



/* new Nav */
@media only screen and (max-width: 1290px) {
	.SimilarItems-collection {
		margin-left: 0px;
		padding: 135px 90px calc(135px + 97px) 90px;
	}

	.SimilarItems-collection .buyBut:hover {background-color: #363636;}
	.SimilarItems-collection .buyBut:active {background-color: #363636;}
	.SimilarItems-collection .buyBut:hover p {margin-left: 20px;}
}

@media only screen and (max-width: 950px) {
	.SimilarItems-collection {
		padding: 135px 65px calc(135px + 55px) 65px;
	}
}



/* phone */
@media only screen and (max-width: 870px) {

	.SimilarItems-collection {
		align-items: center;
		padding-bottom: 230px;
	}

	/* Background */
	.SimilarItems-collection .background {
		grid-column: 1/2;
		grid-row: 1/5;
		margin-top: 45px;
	}


	/* Images */
	.SimilarItems-collection .images {
		grid-column: 1/2;
		grid-row: 1/2;
		padding: 0 36px 36px 36px;
		background-color: rgba(0, 0, 0, 0);
		display: block; 
	}




	/* Item Infos */
	.SimilarItems-collection .itemInfos {
		grid-column: 1/2;
		grid-row: 2/3;
		margin: 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 36px;
	}

	.SimilarItems-collection .brand {
		font-weight: 800;
		font-size: 16px;
		color: #282828;
		letter-spacing: 4px;
	}

	.SimilarItems-collection .title {
		width: auto;
		margin-top: 6px;
		font-size: 9px;
		color: #282828;
		letter-spacing: 3px;
		padding: 0;
		text-align: center;
	}

	.SimilarItems-collection .priceP {
		width: auto;
		margin: 16px 0px 0 0px;
	}



	/* Buy & Stocklist Button Infos */
	.SimilarItems-collection .buyBut {
		grid-column: 1/2;
		grid-row: 4/5;
		margin-left: 36px;
		width: 216px;
		height: 40px;
		background-color: #363636;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.SimilarItems-collection .buyBut p {
		margin: 0;
	}

	.SimilarItems-collection .buyBut:hover {background-color: #363636;}
	.SimilarItems-collection .buyBut:active {background-color: #363636;}
	.SimilarItems-collection .buyBut:hover p {margin-left: 0;}

	.SimilarItems-collection .stocklist {
		grid-column: 1/2;
		grid-row: 3/4;
		margin: 22px 85px;
		font-size: 10px;
		color: #282828;
		letter-spacing: 3px;
		text-align: center;	
		text-transform: uppercase;
		text-decoration: underline;
	}


	/* STOCKLIST OVERLAY */
	.SimilarItems-stocklist-container {
		grid-column: 1/2;
		grid-row: 1/5;
		width: 100%;
		position: relative;
		margin-top: auto;
	}
	
	.SimilarItems-stocklist-container .closeButton {
		height: 29px;
		padding: 12px 0 14px 0;
		display: flex;
		justify-content: center;
	}
	
	.SimilarItems-stocklist-container .closeButton:active div {
		background-color: #FFFFFF;
	}
	
	.SimilarItems-stocklist-container .listButton {
		height: 49px;
		padding: 8px 20px;
		margin-top: 4px;
	}

	.SimilarItems-stocklist-container .listContainer:last-child {
		margin-bottom: 12px;
	}
	
	.SimilarItems-stocklist-container .brand {
		font-size: 16px;
		letter-spacing: 3px;
		text-align: center;
	}
	
	.SimilarItems-stocklist-container .stock {
		font-size: 9px;
		text-align: center;
		margin-top: 3px;
	}
	
	.SimilarItems-stocklist-container .listButton:active .brand {
		color: white;
	}

	.SimilarItems-stocklistOverlay {
		grid-column: 1/2;
		grid-row: 1/5;
		background-color: rgba(0,0,0,0.0);
		z-index: 10;
	}
}

.Page404 {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Page404 .wrapper {
	display: inline;
}

.Page404 h1 {
	font-size: 28px;
	letter-spacing: 5px;
	font-weight: 700;
}

.Page404 h2 {
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 2px;
	margin: 8px 0 0 0;
}

.Page404 .link p {
	padding: 10px 0 0 29px;
	background-color: #303030;
	color: white;
	width: 164px;
	height: 36px;
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 6px;
	text-align: left;
	margin-top: 54px;
}


@media only screen and (max-width: 1100px) {
	.Page404 .wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.Page404 .link p {
		padding-left: 0;
		text-align: center;
		margin-top: 0;
	}

	.Page404 .link {
		margin-top: 54px;
	}

	.Page404 h2 {
		font-size: 10px;
		font-weight: 600;
		letter-spacing: 1.5px;
		text-align: center;
		margin: 8px 15px 0 15px;
	}

	.WholePage404 {
		position: relative;
		padding-bottom: 170px;
	}
}

.Impressum {
	position: relative;
}

.Impressum-wrapper {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Impressum-wrapper .wrapper {
	width: 60vw;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	margin-top: 95px;
}

.Impressum-wrapper .header {
	font-size: 20px;
	font-weight: 800;
	letter-spacing: 2.2px;
	margin-bottom: 61px;
}

.Impressum-wrapper .infos-wrapper {
	display: flex;
}

.Impressum-wrapper .infos-wrapper .seconde_container {
	margin-left: 292px;
}

.Impressum-wrapper .title {
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 1.27px;
	text-align: left;
	margin-bottom: 11px;
	margin-top: 23px;
}	

.Impressum-wrapper .content {
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 3px;
}	


.Impressum-wrapper .infos-wrapper .content-m {
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 24px;
}	

.Impressum-wrapper .content-recht {
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 24px;
}	

.Impressum-wrapper .block-container {
	margin-top: 49px;
}



/* ___________ TABLET ___________ */
@media only screen and (max-width: 1100px) {
	.Impressum-wrapper {
		min-height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.Impressum-wrapper .wrapper {
		margin-top: 0px;
		min-height: 100vh;
		width: 100vw;
		padding: 120px 40px 180px 40px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
	}

	.Impressum-wrapper .infos-wrapper .seconde_container {
		margin-left: 30px;
	}

	.Impressum-wrapper .content {
		letter-spacing: 0px;
	}	
}


@media only screen and (max-width: 750px) {
	.Impressum-wrapper .wrapper {
		padding: 80px 20px 240px 20px;
	}


	.Impressum-wrapper .infos-wrapper {
		flex-direction: column;
	}

	.Impressum-wrapper .infos-wrapper .seconde_container {
		margin-left: 0px;
	}

	.Impressum-wrapper .header {
		margin-bottom: 31px;
	}

	.Impressum-wrapper a {
		color: #3FA1EC;
		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;
	
		-ms-word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;
	
		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}

	.Impressum-wrapper .content {
		line-height: 17px;
		font-size: 13px;
	}	
}
.AppDatenschutz p {
	text-transform: none;
}
.AppDatenschutz .h1 {
	z-index: 10;
	text-decoration: none;
}
.AppDatenschutz .h1 {
	font-weight: 700;
	font-size: 20px;
	letter-spacing: 4.5px;
	text-decoration: none;
}

.AppDatenschutz .h1 span {
	font-weight: 400;
}

.AppDatenschutz .brand {
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 100px 0;
}

.AppDatenschutz .wrapper {
	display: flex;
	align-items: flex-end;
}

.AppDatenschutz .h2 {
	margin-left: 12px;
	font-size: 13px;
	font-weight: 600;
}


.AppDatenschutz .extern-link {
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.AppDatenschutz #link {
	font-size: 12px;
}



/* PHONE */
@media only screen and (max-width: 850px) {
	
	.AppDatenschutz .brand {
		margin: 30px 0;
	}

	.AppDatenschutz .h1 {
		font-size: 19px;
		letter-spacing: 1px;
	}
}
.loading-outer-container {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
}

.Payout .loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.loading-Payout p {
	margin-top: 30px;
	font-size: 11px;
}
.RedirectPaypal {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.RedirectPaypal .loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.loading-container p {
	margin-top: 30px;
	font-size: 11px;
}
.Profil {
	position: relative;
	min-height: 100vh;
}



/* PROFIL INFOS */
.info-wrapper {
	margin-top: 19px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.profil-infos-container {
	width: calc(100vw - 94px * 2);
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 199; 
	grid-column-gap: 94px; 
	-webkit-column-gap: 94px; 
	        column-gap: 94px;
	grid-row-gap: 94px;
	row-gap: 94px;
	max-width: 1130px;
}

/* Image */
.profil-image-container {
	display: flex;
	justify-content: center;
}

.profil-image {
	width: 199px;
	height: 199px;
	border-radius: 22px;
}

/* Infos */
.infos {
	grid-column: 2/4;
}

.infos .name {
	font-size: 30px;
	font-weight: 800;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: inherit;
	text-transform: none;
}

.infos .username {
	font-size: 15px;
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: inherit;
	text-transform: none;
}

.infos .follower-container {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-top: 17px;
}

.infos .follower {
	font-size: 16px;
	font-weight: 700;
}

.infos .follower-title {
	font-size: 11px;
	padding-left: 8px;
	font-weight: 600;
	padding-bottom: 1px;
}

.infos .bio {
	font-weight: 700;
	font-size: 13px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: inherit;
	text-transform: none;
}

.shine {
	display: none;
}



.outfits-title {
	margin: 21px 0 21px 23px;
	font-size: 14px;
	font-weight: 700;
	display: none;
}



/* OUTFITS */
.outfit-wrapper {
	margin-top: 115px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 200px;
}

.outfits-container {
	width: calc(100vw - 94px * 2);
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 392px; 
	grid-column-gap: 94px; 
	-webkit-column-gap: 94px; 
	        column-gap: 94px;
	grid-row-gap: 94px;
	row-gap: 94px;
	max-width: 1130px;
}

.outfits-container .outfit-image {
	width: 100%;
	height: 100%;
	background-color: rgb(0,0,0, 0.1);
}

.outfit-image {
	width: 100%;
	height: 100%;
}


@media only screen and (max-width: 1318px) {
	.outfits-container {
		grid-template-rows: calc((100vw - 94px * 4) / 3 * 1.25);
	}
}


@media only screen and (max-width: 1100px) {
	.outfits-container {
		grid-template-rows: calc((100vw - 40px * 4) / 3 * 1.25);
		grid-column-gap: 40px;
		-webkit-column-gap: 40px;
		        column-gap: 40px;
		grid-row-gap: 40px;
		row-gap: 40px;
		width: calc(100vw - 40px * 2);
	}

	.profil-infos-container {
		grid-column-gap: 40px;
		-webkit-column-gap: 40px;
		        column-gap: 40px;
		grid-row-gap: 40px;
		row-gap: 40px;
		width: calc(100vw - 40px * 2);
	}
}

@media only screen and (max-width: 740px) {
	.outfits-container {
		grid-template-rows: calc((100vw - 5px * 2) / 3 * 1.25);
		grid-column-gap: 5px;
		-webkit-column-gap: 5px;
		        column-gap: 5px;
		grid-row-gap: 5px;
		row-gap: 5px;
		width: 100vw;
	}

	.profil-infos-container {
		grid-column-gap: 5px;
		-webkit-column-gap: 5px;
		        column-gap: 5px;
		grid-row-gap: 5px;
		row-gap: 5px;
		width: 100vw;
	}

	.profil-image {
		width: 80%;
		height: 80%;
		border-radius: 22px;
	}

	.profil-image-container {
		justify-content: flex-end;
	}

	.infos {
		margin-left: 30px;
	}
}

@media only screen and (max-width: 580px) {
	/* HEADER */
	.header-container {
		height: 59px;
		padding: 0 23px;
	}

	/* Outfits */
	.outfits-container {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: calc((100vw - 5px) / 2 * 1.25);
		grid-column-gap: 5px;
		-webkit-column-gap: 5px;
		        column-gap: 5px;
		grid-row-gap: 5px;
		row-gap: 5px;
	}

	/* Infos */
	.profil-infos-container {
		grid-template-columns: 100vw;
		grid-template-rows: 100vw;
		width: 100vw;
		height: 100vw;
	}

	.info-wrapper {
		position: relative;
	}

	.profil-image {
		width: 100vw;
		height: 100vw;
		border-radius: 0;
	}

	.infos {
		grid-column: 1/2;
	}

	.infos .name {
		position: absolute;
		bottom: 40px; left: 23px;
		color: white;
		max-width: calc(100vw - 90px);
	}
	
	.infos .username {
		position: absolute;
		bottom: 23px; left: 23px;
		color: white;
		max-width: calc(100vw - 90px);
	}

	.infos .follower-container {
		position: absolute;
		bottom: 23px; right: 23px;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 17px;
		background-color: rgba(0, 0, 0, 0);
	}

	.infos .follower {
		color: white;
	}

	.infos .follower-title {
		color: white;
		padding-left: 0;
		padding-bottom: 0;
		font-weight: 700;
		opacity: 0.9;
	}
	
	.infos .bio {

	}

	.info-wrapper {
		margin: 0;
	}

	.outfit-wrapper {
		margin-top: 0;
	}

	.outfits-title {
		display: inline-block;
	}	

	.shine {
		position: absolute;
		bottom: 0; right: 0; left: 0;
		display: inline-block;
		height: 80px;
		width: 100vw;
		background: rgb(0,0,0);
		background: linear-gradient(0deg, rgba(0,0,0,0.13) 0%, rgba(255,255,255,0) 100%);
	} 
}
/* HEADER */
.header-V2-container {
	height: 91px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 94px;
}

.header-V2-container .title {
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.9px;
}

.open-in-app-button {
	border-radius: 7px;
	width: 139px;
	height: 35px;
	font-size: 12px;
	color: white;
	font-weight: 700;
	background: rgb(194,216,229);
	background: linear-gradient(90deg, rgba(194,216,229,1) 0%, rgba(101,173,229,1) 100%);
}

@media only screen and (max-width: 580px) {
	/* HEADER */
	.header-V2-container {
		height: 59px;
		padding: 0 23px;
	}
}
.Reset-Password {
	position: relative;
	min-height: 100vh;
}

.Reset-Password h2 {
	font-size: 22px;
	font-weight: 800;
}

.content-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 90px;
	padding-bottom: 270px;
}

.Reset-Password .form {
	display: flex;
	flex-direction: column;
	align-items: center;
}


.Reset-Password input[type=password] {
	border: 0;
	background-color: #F2F2F2;
	height: 47px;
	width: calc(100vw - 40px);
	max-width: 320px;
	border-radius: 13px;
	text-align: center;
	font-weight: 600;
	font-size: 14px;
	color: #282828;
	font-family: 'Montserrat', sans-serif;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #282828;
	opacity: 0.3;
	font-size: 13px;
  }

:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #282828;
	opacity: 0.3;
	font-size: 13px;
  }

::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #282828;
	opacity: 0.3;
	font-size: 13px;
  }

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #282828;
	opacity: 0.3;
	font-size: 13px;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #282828;
	opacity: 0.3;
	font-size: 13px;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
	color: #282828;
	opacity: 0.3;
	font-size: 13px;
  }

input:focus {
	outline: none;
}


.Reset-Password .password-text-field {
	margin-top: 84px;
}

.Reset-Password .repeat-password-text-field {
	margin-top: 20px;
}

.error-label {
	color: red;
	font-size: 12px;
	margin-top: 50px;
}

.submit-button {
	background-color: #282828;
	color: white;
	height: 47px;
	border-radius: 13px;
	font-size: 13px;
	font-weight: 700;
	width: 169px;
	margin-top: 20px;
	border: none;
	cursor: pointer;
	font-family: 'Montserrat', sans-serif;
	-webkit-appearance: none;
}




/* SUCCESS */
.Reset-Password .check-icon {
	width: 60px;
	height: 45px;
	object-fit: cover;
	-o-object-fit: cover;
	margin-top: 125px;
}

.Reset-Password .success {
	margin-top: 42px;
	font-size: 12px;
	font-weight: 600;
}



@media only screen and (max-width: 580px) {
	.content-container {
		margin-top: 50px;
	}
}
.TermsOfUse {
	position: relative;
}


.TermsOfUse .terms-content {
	padding: 150px 100px;
	width: 100vw;
}


.TermsOfUse h2 {
	font-size: 25px;
}

.terms-content p {
	text-transform: none;
	font-weight: 500;
	opacity: 0.8;
	margin-top: 30px;
}

.TermsOfUse .info {
	list-style: disc outside none;
	list-style: initial;
}


/* MAIN LISTE */
.liste-container {
	margin-top: 70px;
}

.TermsOfUse ol {
	list-style-position: inside;
}

.TermsOfUse li {
	font-size: 20px;
	font-weight: 700;
	margin-top: 70px;
}

.TermsOfUse ul {
	list-style-position: inside;
	margin-top: 10px;
	list-style: none;
	background-color: rgba(0, 0, 0, 0);
}

.TermsOfUse ul li {
	margin-left: 30px;
	font-size: 16px;
	font-weight: 500;
	margin-top: 20px;
}

.TermsOfUse .inner-num-list {
	padding-left: 60px;
}


@media only screen and (max-width: 1200px) {
	.TermsOfUse .terms-content {
		padding: 100px 40px 200px 40px;
		width: 100vw;
	}
}
