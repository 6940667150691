.collection {
	padding: 10.8125rem 07.083333vw 175px 07.083333vw;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 29.861111vw;
	grid-gap: 07.083333vw;
	z-index: 2;
}
/* .img {
	background-color: black;
	box-shadow: 0px 02.083333vw 03.472222vw rgba(0, 0, 0, 0.1);
} */

.imgContainer {
	background-color: white;
	box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
}

.image {
	object-fit: cover;
	width: 100%;
	height: 29.861111vw;
	object-position: center;
	image-rendering: auto;
	image-rendering: crisp-edges;
	image-rendering: pixelated;
	image-rendering: inherit;
}





/* TALET */
@media only screen and (max-width: 1200px) {
	.collection {
		padding: 128px 0 125px 0;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 61.66vw;
		grid-gap: 5px;
		background-color: white;
	}

	.imgContainer {box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);}

	.image {height: 61.66vw;}
}

/* PHONE */
@media only screen and (max-width: 750px) {
	.collection {
		padding: 70px 0 210px 0;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 61.66vw;
		grid-gap: 5px;
		box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
	}

	.imgContainer {box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);}

	.image {height: 61.66vw;}
}