
/* Overlay */
.Share-overlay {
	display: none;
	z-index: 110;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0; right: 0; bottom: 0; left: 0;
}

@keyframes shareColorchange {
  0%   {background: rgba(0,0,0,0.0);}
  100% {background: rgba(0,0,0, .72);}
}

@keyframes shareColorchangeBack {
	0%   {background: rgba(0,0,0,0.72);}
	100% {background: rgba(0,0,0,0.0);}
}

@keyframes blurChange {
	0%   {backdrop-filter: blur(0px);}
	100% {backdrop-filter: blur(4px);}
}
  
@keyframes blurChangeBack {
	0%   {backdrop-filter: blur(4px);}
	100% {backdrop-filter: blur(0px);}
}
  

/* Card */
.Share-container {
	width: 85%;
	max-width: 483px;
	height: 372px;
	background: #F6F6F6;
	display: grid;
	grid-template-columns: auto 89px;
	grid-template-rows: auto 72px;
}

@keyframes opacityChange {
	0%   {opacity: 0;}
	100% {opacity: 1;}
  }
  
  @keyframes opacityChangeBack {
	0%   {opacity: 1;}
	100% {opacity: 0;}
  }

p { 
	text-transform: uppercase; 
	background-color: rgba(0,0,0,0.0);
}

.Share-container div { background-color: rgba(0,0,0,0.0); }


/* Sozial Buttons */
.Share-container .sozialButs {
	grid-column: 1/2;
	grid-row: 1/2;
	padding: 65px 0 31px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between
}

.Share-container .sozialButs button {
	background-color: white;
	height: 50px;
	text-align: right;
	padding-right: 21px;
	box-shadow: 20px 20px 40px rgba(0,0,0, .08);
	transition: .5s;
}

.Share-container .sozialButs button:hover {
	transform: translateY(-2px)
}


.Share-container .sozialButs button .icon {
	background: rgba(0,0,0,0.0);
	width: 20px;
	height: 17px; 
	color: #282828;
}


/* Title */
.Share-container .title-container {
	grid-column: 2/3;
	grid-row: 1/3;
	padding: 23px 31px 0 39px;
}	

.Share-container .title {
	text-align: center;
	font-weight: 700;
	font-size: 24px;
	line-height: 40px;
	color: #282828;
}	


/* Cancel Button */
.Share-container .cancelBut {
	background-color: rgba(0,0,0,0.0);
	position: relative;
	grid-column: 1/3;
	grid-row: 2/3;
}

.Share-container .cancelBut p {
	position: absolute;
	right: 89px;
	top: 19px;
	font-weight: 600;
	font-size: 15px;
	color: #3FA1EC;
}




/* Link Copied Alert */
.linkCopiedAlert {
	position: fixed;
	background-color: #282828;
	width: 100vw;
	height: 34px;
	bottom: -34px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.35s ease-in-out;
	color: white;
	font-size: 10px;
	font-weight: 700;
	z-index: 50;
}
