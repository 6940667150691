.Header {
	background-color: rgb(0, 0, 0, 0);
	position: fixed;
	width: max-content;
	height: 160px;
	margin: 45px 0 0 45px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	z-index: 10;
}

.Header div {background-color: rgb(0, 0, 0, 0);}

.Header .brand {
	width: max-content;
	font-size: 21px;
	font-weight: 800;
	color: #282828;
	letter-spacing: 3px;
	background-color: rgba(0, 0, 0, 0);
}

.Header .choose-container {
	width: max-content;
	height: 54px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.Header .choose-container .gender {
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 5px;
	color: #282828;
}

.headerFooter {z-index: 10;}


.headerFooter div {background-color: rgb(0, 0, 0, 0)}

.Terms {
	position: fixed;
	left: 0;
	bottom: -11px;
	margin: 45px;
	display: flex;
	justify-content: space-between;
	transform-origin: top left;
	-ms-transform: rotate(-90deg); /* IE 9 */
	-webkit-transform: rotate(-90deg); /* Safari 3-8 */
	transform: rotate(-90deg);
	flex-wrap: wrap;
	z-index: 10;
}

.Terms .info {
	margin-right: 40px;
	font-size: 9px;
	font-weight: 600;
	letter-spacing: 8.4px;
	color: #282828;
}



@media only screen and (max-height: 950px) {
	.Terms .info {
		letter-spacing: 5.5px;
	}
}

@media only screen and (max-height: 800px) {
	.Terms .info {
		margin-right: 20px;
		letter-spacing: 3px;
	}
}

@media only screen and (max-height: 635px) {
	.Terms .info {
		letter-spacing: 0px;
	}
}





/* SOZIAL */
.sozial-conatiner {
	position: fixed;
	bottom: 45px;
	left: 101px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	z-index: 10;
}


.sozialIcon-container {
	display: grid;
	grid-template-rows: 13px 16px 13px;
}

.sozialIcon-container .facebookBut {
	width: 20px;
	height: 20px;
	background-color: rgba(0, 0, 0, 0);
}

.sozialIcon-container .facebookIcon {
	width: auto;
	height: 15px;
}

.sozialIcon-container .instagramBut {
	width: 20px;
	height: 20px;
	grid-row: 3/4;
	background-color: rgba(0, 0, 0, 0);
}

.sozialIcon-container .instagramIcon {
	width: auto;
	height: 15px;
}



.appStore {
	display: flex;
	align-items: flex-end;
	margin-left: 45px;
}

.appStore .apple {
	width: 13px;
	height: 13px;
	background-color: rgba(0, 0, 0, 0);
}

.appStore p {
	font-size: 9px;
	font-weight: 500;
	letter-spacing: 2px;
	margin-left: 11px;
}