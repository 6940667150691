
/*  ---------- MAIN Image ---------- */
.mainImage-container {
	z-index: 1;
	margin-left: 19.44vw;
	margin-left: 22vw;
	width: 47.36vw;
	/* margin-left: 280px; */
	height: 100vh;
	position: sticky;
	top: 0;
	background-color: rgba(0,0,0,0.0)
}


.mainImage-container .imageButton {
	width: 47.36vw;
	height: 100%;
	position: relative;
}

.mainImage {
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0;
	object-fit: cover;
	-o-object-fit: cover;
	width: 47.36vw;
	height: 100%;
}






/* ---------- Pointer ---------- */
.pointer-conatiner {
	z-index: 2;
	position: absolute;
	background-color: rgba(0,0,0,0.0);
	min-width: 0;
	max-width: auto;
	height: 30px;
	transition: .5s;
	-webkit-transition: .5s;
	-webkit-transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
	transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
}

.pointer-conatiner img { background-color: rgba(0,0,0,0.0); }

.pointer-conatiner button {
	background-color: rgba(0,0,0,0.0);
	width: 100%;
	height: 100%;
}



.pointer-button-container {
	background-color: rgba(0,0,0,0.0);
	display: grid;
	align-items: center;
	grid-template-columns: 12px 4px 4px minmax(10px, auto);
}


.pointer-triangle {
	background-color: rgba(0,0,0,0.0);
	object-fit: contain;
	grid-column: 1/3;
	grid-row: 1/2;
	z-index: 1;
}

.pointer-circle {
	object-fit: contain;
	grid-column: 2/4;
	grid-row: 1/2;
	z-index: 2;

}

/* Pointer Brand Name */
.pointer-title-container {
	grid-column: 3/5;
	grid-row: 1/2;
	height: 100%;
	padding-left: 4px;
	border-radius: 0px 4px 4px 0px;
	background-color: white;
	display: flex;
	align-items: center;
}

.pointer-title-container p {
	padding: 5px;
	font-weight: 800;
	font-size: 0.8125rem;
	white-space: nowrap;
	text-overflow: ellipsis;
}


/* Share */
.mainImage-container .share {
	position: absolute;
	width: 41px;
	height: 41px;
	background-color: rgba(0, 0, 0, 0);
	z-index: 10;
	top: 20px;
	left: 20px;
}

.mainImage-container .share img {
	background-color: rgba(0, 0, 0, 0);
	opacity: 0.7;
}


/* PRESS ICON */
.mainImage-container .press-icon {
	z-index: 10;
	position: absolute;
	top: 20px;
	right: 20px;
	width: 23px;
    height: 23px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, .9);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}



/* Tablet */
@media only screen and (max-width: 1290px) {
	.mainImage-container {
		margin-left: 0vw;
		width: 55.45vw;
	}

	.mainImage {
		width: 55.45vw;
	}
}


/* Phone */
@media only screen and (max-width: 934px) {
	.mainImage-container {
		margin-left: 0vw;
		width: 100vw;
		position: static;
	}

	.mainImage {
		width: 100vw;
	}
}

@media only screen and (min-height: 1200px) and (max-width: 1290px) {
	.mainImage-container {
		margin-left: 0vw;
		width: 100vw;
		position: static;
	}

	.mainImage {
		width: 100vw;
	}
}
