.Profil {
	position: relative;
	min-height: 100vh;
}



/* PROFIL INFOS */
.info-wrapper {
	margin-top: 19px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.profil-infos-container {
	width: calc(100vw - 94px * 2);
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 199; 
	column-gap: 94px;
	row-gap: 94px;
	max-width: 1130px;
}

/* Image */
.profil-image-container {
	display: flex;
	justify-content: center;
}

.profil-image {
	width: 199px;
	height: 199px;
	border-radius: 22px;
}

/* Infos */
.infos {
	grid-column: 2/4;
}

.infos .name {
	font-size: 30px;
	font-weight: 800;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: inherit;
	text-transform: none;
}

.infos .username {
	font-size: 15px;
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: inherit;
	text-transform: none;
}

.infos .follower-container {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-top: 17px;
}

.infos .follower {
	font-size: 16px;
	font-weight: 700;
}

.infos .follower-title {
	font-size: 11px;
	padding-left: 8px;
	font-weight: 600;
	padding-bottom: 1px;
}

.infos .bio {
	font-weight: 700;
	font-size: 13px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: inherit;
	text-transform: none;
}

.shine {
	display: none;
}



.outfits-title {
	margin: 21px 0 21px 23px;
	font-size: 14px;
	font-weight: 700;
	display: none;
}



/* OUTFITS */
.outfit-wrapper {
	margin-top: 115px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 200px;
}

.outfits-container {
	width: calc(100vw - 94px * 2);
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 392px; 
	column-gap: 94px;
	row-gap: 94px;
	max-width: 1130px;
}

.outfits-container .outfit-image {
	width: 100%;
	height: 100%;
	background-color: rgb(0,0,0, 0.1);
}

.outfit-image {
	width: 100%;
	height: 100%;
}


@media only screen and (max-width: 1318px) {
	.outfits-container {
		grid-template-rows: calc((100vw - 94px * 4) / 3 * 1.25);
	}
}


@media only screen and (max-width: 1100px) {
	.outfits-container {
		grid-template-rows: calc((100vw - 40px * 4) / 3 * 1.25);
		column-gap: 40px;
		row-gap: 40px;
		width: calc(100vw - 40px * 2);
	}

	.profil-infos-container {
		column-gap: 40px;
		row-gap: 40px;
		width: calc(100vw - 40px * 2);
	}
}

@media only screen and (max-width: 740px) {
	.outfits-container {
		grid-template-rows: calc((100vw - 5px * 2) / 3 * 1.25);
		column-gap: 5px;
		row-gap: 5px;
		width: 100vw;
	}

	.profil-infos-container {
		column-gap: 5px;
		row-gap: 5px;
		width: 100vw;
	}

	.profil-image {
		width: 80%;
		height: 80%;
		border-radius: 22px;
	}

	.profil-image-container {
		justify-content: flex-end;
	}

	.infos {
		margin-left: 30px;
	}
}

@media only screen and (max-width: 580px) {
	/* HEADER */
	.header-container {
		height: 59px;
		padding: 0 23px;
	}

	/* Outfits */
	.outfits-container {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: calc((100vw - 5px) / 2 * 1.25);
		column-gap: 5px;
		row-gap: 5px;
	}

	/* Infos */
	.profil-infos-container {
		grid-template-columns: 100vw;
		grid-template-rows: 100vw;
		width: 100vw;
		height: 100vw;
	}

	.info-wrapper {
		position: relative;
	}

	.profil-image {
		width: 100vw;
		height: 100vw;
		border-radius: 0;
	}

	.infos {
		grid-column: 1/2;
	}

	.infos .name {
		position: absolute;
		bottom: 40px; left: 23px;
		color: white;
		max-width: calc(100vw - 90px);
	}
	
	.infos .username {
		position: absolute;
		bottom: 23px; left: 23px;
		color: white;
		max-width: calc(100vw - 90px);
	}

	.infos .follower-container {
		position: absolute;
		bottom: 23px; right: 23px;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 17px;
		background-color: rgba(0, 0, 0, 0);
	}

	.infos .follower {
		color: white;
	}

	.infos .follower-title {
		color: white;
		padding-left: 0;
		padding-bottom: 0;
		font-weight: 700;
		opacity: 0.9;
	}
	
	.infos .bio {

	}

	.info-wrapper {
		margin: 0;
	}

	.outfit-wrapper {
		margin-top: 0;
	}

	.outfits-title {
		display: inline-block;
	}	

	.shine {
		position: absolute;
		bottom: 0; right: 0; left: 0;
		display: inline-block;
		height: 80px;
		width: 100vw;
		background: rgb(0,0,0);
		background: linear-gradient(0deg, rgba(0,0,0,0.13) 0%, rgba(255,255,255,0) 100%);
	} 
}