.loadingAlert {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0);
}

.loadingAlert p {
	font-weight: 600;
	font-size: 10px;
	color: #282828;
	letter-spacing: 2px;
}

.comingsoon-div {
	position: absolute;
	top: 0; right: 0; left: 0; bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.comingsoon-div p {
	font-size: 12px;
	letter-spacing: 3px;
	font-weight: 600;
}