
a {
  text-decoration: none;
  color: #282828;
	background-color: rgba(0, 0, 0, 0)
}

button:active {
  color: #282828;
}
