.loading-outer-container {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
}

.Payout .loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.loading-Payout p {
	margin-top: 30px;
	font-size: 11px;
}