.AppDatenschutz p {
	text-transform: none;
}
.AppDatenschutz .h1 {
	z-index: 10;
	text-decoration: none;
}
.AppDatenschutz .h1 {
	font-weight: 700;
	font-size: 20px;
	letter-spacing: 4.5px;
	text-decoration: none;
}

.AppDatenschutz .h1 span {
	font-weight: 400;
}

.AppDatenschutz .brand {
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 100px 0;
}

.AppDatenschutz .wrapper {
	display: flex;
	align-items: flex-end;
}

.AppDatenschutz .h2 {
	margin-left: 12px;
	font-size: 13px;
	font-weight: 600;
}


.AppDatenschutz .extern-link {
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.AppDatenschutz #link {
	font-size: 12px;
}



/* PHONE */
@media only screen and (max-width: 850px) {
	
	.AppDatenschutz .brand {
		margin: 30px 0;
	}

	.AppDatenschutz .h1 {
		font-size: 19px;
		letter-spacing: 1px;
	}
}